import { useEffect, useState, useRef, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { searchDrugsByName, setDrugsList } from "../../reducers/drugSearch";
import PricesDetails from "./PricesDetails";
import RightReviewCoupon from "./rightReviewCoupon";
import { useNavigate } from "react-router-dom";
import avatar from "../../assets/images/RxLink assets/avatar icon.svg";
import trash from "../../assets/images/RxLink assets/new assets/Trash_light.svg";
import { toast } from "react-toastify";
import SearchPharmacy from "../searchPharmacy";
import { getUserPharmacyList, addOrUpdatePharmacy, removePharmacy } from "../searchPharmacy/api";
import { fetchMedicationDetail, setFromSearchMap, setPharmacyList, setShowPahrmacyMap, setFromDrawer, setPencilClick, setPharmaInfo } from "../../reducers/medication";
import { filterPharmacyNames } from "../../utilities/FilterPahrmacy";
import { fetchInsuranceInfo } from "../../reducers/getInsuranceInfo";
import { setFromCoupon, setShowCoupon, setTriggerModelFromCoupon } from "../../reducers/medication";
import { filterNPIResults } from "../../utilities/NPIStore";
import { useStyleMediaQuery } from "../coupan/hooks";
import { store } from "../../store";
import { fetchMedicationList } from "../../reducers/javaMedications";
import { fetchCostPlusDrugPrice } from "../../reducers/costPlusDrugPrice";
import { fetchDrugsPrices, fetchRxSenseDrugPrices, setDrugDetails, setRxSenseDrugDetails } from "../../reducers/drugPrices";
import { fetchCopayPrices, fetchPrismaRules, setDisclaimer } from "../../reducers/copayPrices";
import { addOrUpdateMedication } from "../../reducers/saveOrUpdateMedication";
import { deleteMedication } from "../../reducers/removeMedication";
import { setInsuranceInfo, setIsUpdatingPhamracy, setIsRemovingPharmacy, setProgress, setProgressLock, setResponseState, resetProgressOperation } from "../../reducers/global";
import { mapUniqueRxToHippo, mapUniqueHippoToHippo, mapUniqueCareCardToHippo, mapUniqueRxLessToHippo } from "./mapper";
import { successToast, errorToast, successToastWithId } from "../toast/toast";
import { useWindowWidth } from "../searchPharmacy/useWindowWidth";
import CouponCard from "../coupan/CoupanCard";
import Spinner from 'react-bootstrap/Spinner';
import { setAnimatedLoading } from "../../reducers/global";
import ariaAnnounce from "../../utilities/useAnnounceText";
import { useGetUser } from "../../utilities/useGetUser";
import { mapData } from "./mapData";
import arrowMobile from "../../assets/images/arrow-forMobile.png";
import mobileImage from "../../assets/images/Mobile-image.png";
import arrowDesktop from "../../assets/images/arrow-forDesktopo.png";
import desktopImage from "../../assets/images/Desktop-Image.png";
import { fetchCareCardInfo } from "../../reducers/careCardInfo";
import { fetchRxLessDrugPrices } from "../../reducers/rxLessDetails";
import { normalizeCompanyName } from "../../utilities/getNormalizedName";
import { searchPharmacyType } from "../PharmacyLogo";
import { customMedications } from "./customMedList";


const MedicationSearch = ({ ownPosition, setOwnPosition, setIp }) => {

  const [selectMapValues, setSelectMapValues] = useState([]);
  const [originalFilter, setOriginalFilter] = useState(null);
  const [insuranceDetail, setInsuranceDetail] = useState([]);
  const [hasInsurance, setHasInsurance] = useState(null);
  const [addingMedicine, setAddingMedicine] = useState(false);
  const [fullMedicinesList, setFullMedicineList] = useState([]);

  const rightReviewRef = useRef(null)
  const { matches: isTab } = useStyleMediaQuery({ mixOrMax: 'max', widthOrHeight: 'width', value: 991 });

  const [pharmacyFlag, setPharmacyFlag] = useState(false);

  const [pricesList, setPricesList] = useState([]);
  const reduxState = store.getState();
  const fromCoupon = reduxState.medication.fromCoupon;
  const triggerModelFromCoupon = reduxState.medication.triggerModelFromCoupon;
  const savedNpi = reduxState.medication.savedNpi;

  const drugPrices = useSelector((state) => state.drugdetails.drugPrices);
  const rxSenseDrugPrices = useSelector((state) => state.drugdetails.rxSenseDrugPrices);
  const animatedLoading = useSelector((state) => state.global.animatedLoading);
  const progress = useSelector((state) => state.global.progress);
  const progressLock = useSelector((state) => state.global.progressLock);
  const responseState = useSelector((state) => state.global.responseState);
  const showPharmacyMap = useSelector((state) => state.medication.showPharmacyMap);
  const showCoupon = useSelector((state) => state.medication.showCoupon);
  const pencilClick = useSelector((state) => state.medication.pencilClick);
  const pharmaInfo = useSelector((state) => state.medication.pharmaInfo);
  const pharmacyList = useSelector((state) => state.medication.pharmacyList);
  const fromDrawer = useSelector((state) => state.medication.fromDrawer);
  const user = useGetUser();
  const userHasPharmacy = user?.pharmacy?.userPharmacySeqNo;
  const loggedInUserPharmacy = user?.pharmacy;
  const hasDefaultPharmacy = userHasPharmacy ? true : false;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (user)
      dispatch(setAnimatedLoading(true));
  }, [])

  const [medication, setMedication] = useState({
    drugId: "",
  });
  const { windowWidth } = useWindowWidth();

  const [searchDrug, setSearchDrug] = useState("");
  const [optionss, setOptionss] = useState([]);
  const [medDrugData, setMedDrugData] = useState([]);
  const [medicationsLength, setMedicationsLength] = useState(0);
  const { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [medicationCoupon, setMedicationCoupon] = useState([]);
  const [SavingOptions, setSavingOptions] = useState(false);
  const [deleteShow, setDeleteShow] = useState(false);
  const [npi, setNpi] = useState("");
  const [pharmacyInfo, setPharmacyInfo] = useState([]);
  const [medicationList, setMedicationList] = useState([]);
  const [hippoDrugsPrices, setHippoDrugsPrices] = useState([]);
  const [costPlusDrugsPrices, setCostPlusDrugsPrices] = useState([]);
  const [rxSenceDrugsPrices, setRxSenceDrugsPrices] = useState([]);
  const [careCardDrugsPrices, setCareCardDrugsPrices] = useState([]);
  const [rxLessDrugsPrices, setRxLessDrugsPrices] = useState([]);
  const [copayPrices, setCopayPrices] = useState([]);
  const [medicationInfo, setMedicationInfo] = useState([]);
  const [sumOfBestPrciesAtPharmacies, setSumOfBestPrciesAtPharmacies] = useState([]);
  const [bestPackage, setBestPackage] = useState([]);
  const [addOrUpdateMed, setAddOrUpdateMed] = useState(null);
  const [quantity, setQuantity] = useState(0);
  const [label, setLabel] = useState("");
  const [form, setForm] = useState("");
  const [dosage, setDosage] = useState("");
  const [removeDrug, setRemoveDrug] = useState({});
  const [disabledIcons, setDisabledIcons] = useState({});
  const [isLoader, setIsLoader] = useState(false);
  const [cheaperOptionAvailable, setCheaperOptionAvailable] = useState(false);
  const [savingPercentage, setSavingPercentage] = useState(0);
  var hippoApiErr = 0;

  const patientInsuranceInfo = reduxState.global.insuranceInfo;
  const isUpdatingPharmacy = reduxState.global.isUpdatingPharmacy;
  const isRemovingPharmacy = reduxState.global.isRemovingPharmacy;
  // const prismaPrices = reduxState.copayPrices.prismaPrices;
  // const prismaPrices = useSelector((state) => state.copayPrices.prismaPrices);
  const [prismaPrices, setPrismaPrices] = useState([]);

  const hasInsuranceInformation = patientInsuranceInfo?.groupId != "" && patientInsuranceInfo?.bin != "" && patientInsuranceInfo?.memberId != "";

  const ref = useRef(null);

  useEffect(() => {
    if (isTab && triggerModelFromCoupon && rightReviewRef.current && pharmaInfo?.bestPrice) {
      setTimeout(() => { rightReviewRef.current.couponScreenToCouponModel() }, 1000)
    }
    else if (isTab && showCoupon && pharmaInfo?.bestPrice) {
      dispatch(setFromCoupon(true));
      dispatch(setShowCoupon(false))
      dispatch(setTriggerModelFromCoupon(true))
    } else if (!isTab && rightReviewRef.current) {
      rightReviewRef.current.couponModelToCouponScreen();
    }
  }, [isTab, showCoupon, pharmaInfo?.bestPrice])

  useEffect(() => {
    if (!fromCoupon) {
      if (hasDefaultPharmacy && !fromDrawer) {
        dispatch(setShowPahrmacyMap(false))
        dispatch(setFromDrawer(false))
      }
      else {
        dispatch(setShowPahrmacyMap(true));
      }
    }
  }, [hasDefaultPharmacy])

  useEffect(() => {
    if (bestPackage?.length > 0) {
      let selectedPharmacy = bestPackage?.filter((x, i) => normalizeCompanyName(x?.chain) == normalizeCompanyName(originalFilter?.[0]?.chain) || normalizeCompanyName(x?.chain) == normalizeCompanyName(originalFilter?.[0]?.pharmacyName)?.toLowerCase());
      if (selectedPharmacy.length == 0) {
        let local = bestPackage?.filter((item) => item?.chain == "local");
        if (local.length > 0) {
          selectedPharmacy = local?.[0]?.pharmaciesList?.filter((x, i) => normalizeCompanyName(x?.chain) == normalizeCompanyName(originalFilter?.[0]?.chain) || normalizeCompanyName(x?.chain) == normalizeCompanyName(originalFilter?.[0]?.pharmacyName)?.toLowerCase());
        }
      }
      setPharmacyInfo(selectedPharmacy[0]);
      dispatch(setPharmaInfo(selectedPharmacy[0]))
    }
    else if (bestPackage?.length === 0) {
      setPharmacyInfo({});
      dispatch(setPharmaInfo({}));
    }
  }, [bestPackage])

  useEffect(() => {
    let cheaperOption = bestPackage.some(
      (option) => option.bestPrice < pharmaInfo?.bestPrice
    );
    if (bestPackage?.length > 0 && !pharmaInfo) {
      setCheaperOptionAvailable(true);
    }
    else {
      setCheaperOptionAvailable(cheaperOption);
    }

  }, [pharmaInfo])

  useEffect(() => {
    let costPercentage = 0;
    costPercentage = (pharmacyInfo?.bestPrice / pharmacyInfo?.estRetailPrice) * 100;
    let percentage = 100 - costPercentage;
    setSavingPercentage(percentage);
  }, [pharmacyInfo]);


  const filteredNpi = useMemo(
    () => {
      let filteredNameItem = filterNPIResults(data, selectMapValues?.pharmacyName);
      if (filteredNameItem.length > 0) {
        const npi = filteredNameItem?.[0]?.number;
        return npi;
      }
      else {
        let randomNPI = data.length > 0 && data?.[0] != undefined ? data?.[0]?.number : "1801153093";
        return randomNPI;
      }
    }
    ,
    [selectMapValues?.pharmacyName, ownPosition?.postalCode]
  );

  const setter = () => [{ ...selectMapValues, lat: ownPosition?.lat, lng: ownPosition?.lng, state: ownPosition?.responseZIP?.region_code, npi: filteredNpi }];

  useEffect(() => {
    let unmounted = false;
    let allApisCalled = rxLessDrugsPrices?.length === medicationList?.length || careCardDrugsPrices?.length === medicationList?.length || rxSenceDrugsPrices?.length === medicationList?.length || hippoDrugsPrices?.length === medicationList?.length;
    let pricesAvailable = hippoDrugsPrices?.length > 0 || rxSenceDrugsPrices.length > 0 || rxLessDrugsPrices.length > 0 || careCardDrugsPrices.length > 0;
    if (allApisCalled && pricesAvailable && selectMapValues.length != 0 && hasDefaultPharmacy && !unmounted && !pharmacyFlag) {
      const pharma = pharmacyList.filter(i => i.isDefault)[0];
      if (pharma != undefined && !fromCoupon) {
        let rxSensePharmacies = [];
        rxSenceDrugsPrices?.map(res => {
          res?.rxsenseResponse?.Value?.PharmacyPricings?.map((pharmacy) => {
            rxSensePharmacies?.push(pharmacy?.Pharmacy?.Name?.toLowerCase());
          })
        });

        let hippoPharmacies = [];
        hippoDrugsPrices?.map(res => {
          res?.drugPrices?.map((pharmacy) => {
            hippoPharmacies?.push(pharmacy?.chain?.toLowerCase());
          })
        });
        const filterRes = filterPharmacyNames(normalizeCompanyName(pharma.pharmacyName),
          [
            ...new Set([
              ...(hippoPharmacies?.map(i => i?.toLowerCase()) || []),
              ...(rxSensePharmacies?.map(i => i?.toLowerCase()) || []),
              ...(rxLessDrugsPrices?.flatMap(i => i?.rxLessResponse?.flatMap(j => j?.name?.toLowerCase()) || []) || []),
              ...(careCardDrugsPrices?.flatMap(i => i?.careCardResponse?.flatMap(j => j?.pharmacy?.name.toLowerCase()) || []) || [])
            ])
          ].filter(Boolean));
        let filterOriginalPharmacy = [];
        hippoDrugsPrices?.map(res => {
          res?.drugPrices?.map((pharmacy) => {
            if (pharmacy?.chain?.toLowerCase() == normalizeCompanyName(filterRes?.name?.toLowerCase())) filterOriginalPharmacy.push(pharmacy);
          })
        });

        if (filterOriginalPharmacy?.length > 0) {
          setOriginalFilter([{ ...filterOriginalPharmacy[0], lat: pharma.latitude, lng: pharma.longitude, state: pharma?.state || null, npi: pharma.npi || null, address: pharma.address, pharmacySeqNo: pharma?.userPharmacySeqNo, zipCode: pharma?.zipcode }]);
          sessionStorage.setItem("selectMapValues", JSON.stringify({ ...filterOriginalPharmacy[0], lat: pharma.latitude, lng: pharma.longitude, state: pharma?.state || null, npi: pharma.npi || null, pharmacySeqNo: pharma?.userPharmacySeqNo, zipCode: pharma?.zipcode }));
        }
        if (filterOriginalPharmacy?.length === 0 && rxSenseDrugPrices.length > 0) {
          rxSenceDrugsPrices?.map(res => {
            res?.rxsenseResponse?.Value?.PharmacyPricings?.map((pharmacy) => {
              if (pharmacy?.Pharmacy?.Name?.toLowerCase() == normalizeCompanyName(filterRes?.name?.toLowerCase()))
                filterOriginalPharmacy.push({
                  ...pharmacy.Pharmacy,
                  pharmacyName: pharmacy?.Pharmacy?.Name?.toLowerCase(),
                  chain: pharmacy?.Pharmacy?.Name?.toLowerCase()
                });
            })
          });
          if (filterOriginalPharmacy?.length > 0) {
            setOriginalFilter([{ ...filterOriginalPharmacy[0], lat: pharma.latitude, lng: pharma.longitude, state: pharma?.state || null, npi: pharma.npi || null, address: pharma.address, pharmacySeqNo: pharma?.userPharmacySeqNo, zipCode: pharma?.zipcode }]);
            sessionStorage.setItem("selectMapValues", JSON.stringify({ ...filterOriginalPharmacy[0], lat: pharma.latitude, lng: pharma.longitude, state: pharma?.state || null, npi: pharma.npi || null, pharmacySeqNo: pharma?.userPharmacySeqNo, zipCode: pharma?.zipcode }));
          }
        }

        if (filterOriginalPharmacy?.length === 0 && rxLessDrugsPrices?.length > 0) {
          filterOriginalPharmacy = (rxLessDrugsPrices?.flatMap(i => i?.rxLessResponse?.flatMap(j => j) || []) || [])?.filter(i => i?.name?.toLowerCase().replace(/[^\w\s]|_/g, "").replace(/\s+/g, " ").trim() === filterRes?.name?.toLowerCase().replace(/[^\w\s]|_/g, "").replace(/\s+/g, " ").trim());
          if (filterOriginalPharmacy?.length > 0) {
            setOriginalFilter([{ ...filterOriginalPharmacy[0], lat: pharma.latitude, lng: pharma.longitude, state: pharma?.state || null, npi: pharma.npi || null, address: pharma.address, pharmacySeqNo: pharma?.userPharmacySeqNo, zipCode: pharma?.zipcode }]);
            sessionStorage.setItem("selectMapValues", JSON.stringify({ ...filterOriginalPharmacy[0], lat: pharma.latitude, lng: pharma.longitude, state: pharma?.state || null, npi: pharma.npi || null, pharmacySeqNo: pharma?.userPharmacySeqNo, zipCode: pharma?.zipcode }));
          }
        }
        if (filterOriginalPharmacy?.length === 0 && careCardDrugsPrices?.length > 0) {
          (careCardDrugsPrices?.flatMap(i => i?.careCardResponse?.flatMap(j => j) || []) || [])?.
            filter(i => {

              if (i?.pharmacy?.name?.toLowerCase() === normalizeCompanyName(filterRes?.name?.toLowerCase())) {
                filterOriginalPharmacy.push({
                  ...i,
                  pharmacyName: i?.pharmacy?.name?.toLowerCase()
                })
              }
              else return false
            });
          if (filterOriginalPharmacy?.length > 0) {
            setOriginalFilter([{ ...filterOriginalPharmacy[0], lat: pharma.latitude, lng: pharma.longitude, state: pharma?.state || null, npi: pharma.npi || null, address: pharma.address, pharmacySeqNo: pharma?.userPharmacySeqNo, zipCode: pharma?.zipcode }]);
            sessionStorage.setItem("selectMapValues", JSON.stringify({ ...filterOriginalPharmacy[0], lat: pharma.latitude, lng: pharma.longitude, state: pharma?.state || null, npi: pharma.npi || null, pharmacySeqNo: pharma?.userPharmacySeqNo, zipCode: pharma?.zipcode }));
          }
        }
        if (filterOriginalPharmacy?.length === 0) {
          setOriginalFilter([pharma]);
        }
      }
      else if (fromCoupon) {
        setOriginalFilter([{ ...selectMapValues, lat: ownPosition?.lat, lng: ownPosition?.lng, state: ownPosition?.responseZIP?.region_code, npi: savedNpi }]);
      }
      else {
        setOriginalFilter(setter());
      }
    }
    else if (allApisCalled && pricesAvailable && selectMapValues.length != 0 && hasDefaultPharmacy && !unmounted && pharmacyFlag) {
      setOriginalFilter(setter());
    }
    else if (allApisCalled && pricesAvailable && selectMapValues.length != 0 && !hasDefaultPharmacy && !unmounted && !pharmacyFlag) {
      setOriginalFilter(setter());
    }
    else if (allApisCalled && pricesAvailable && selectMapValues.length != 0 && !hasDefaultPharmacy && !unmounted && pharmacyFlag) {
      setOriginalFilter(setter());
    }
    return () => {
      unmounted = true
    }
  }, [hippoDrugsPrices, rxSenceDrugsPrices, rxLessDrugsPrices, careCardDrugsPrices, selectMapValues]);

  useEffect(() => {
    if (addOrUpdateMed?.appUserSeqNo) {
      if (!addingMedicine) {
        setAddingMedicine(true);
      }
      dispatch(addOrUpdateMedication(addOrUpdateMed))
        .unwrap()
        .then((x) => {
          if (x?.status == "OK") {
            let index = medicationList?.findIndex((el, i) => el?.drugSeqNo === x?.data?.drugSeqNo);
            if (index !== -1) {
              const newArr = [
                ...medicationList.slice(0, index),
                Object.assign({}, medicationList[index], x?.data),
                ...medicationList.slice(index + 1),
              ];
              setMedicationList(newArr);
            }
            else {
              setMedicationList([x?.data, ...medicationList]);
              setFullMedicineList(prev => [x.data, ...prev])
            }

            let tempSelectedDrug = {
              name: x?.data?.name,
              id: x?.data?.id,
              type: x?.data?.type,
              search_name: x?.data?.search_name,
              dosage: x?.data?.dosage,
              drugSeqNo: x?.data?.drugSeqNo,
              form: x?.data?.form,
              form_plural: x?.data?.form_plural,
              package_size: x?.data?.package_size,
              quantities: x?.data?.quantities,
              default_quantity: x?.data?.default_quantity !== undefined ? x?.data?.default_quantity : x?.data?.quantities[0],
              names: x?.data?.names,
              ndc: x?.data?.ndc,
              manufacturerOffer: x?.data?.manufactorInfo,
              connectiveRxOffer: x?.data?.crxNetPriceDTO?.crxNetPriceSubSeqNo ? x?.data?.crxNetPriceDTO : undefined,
            };
            dispatch(resetProgressOperation("reset"));
            dispatch(setProgress(15));
            setHippoPrices(tempSelectedDrug, x?.data);
            setCostPlusDrugPrices(tempSelectedDrug, x?.data);
            setRxSencePrices(tempSelectedDrug, x?.data);
            setCareCardInfo(tempSelectedDrug, x?.data);
            setRxLessPrices([tempSelectedDrug]);
          }
          else {
            errorToast(x?.message);
            setAddingMedicine(false);
          }
        });
    }
  }, [addOrUpdateMed])

  const fetchMoreMedicines = async () => {
    let loadedMedicines = medicationList?.length;
    let moreMedicines = fullMedicinesList.slice(loadedMedicines, loadedMedicines + 10);
    // console.log({ loadedMedicines, moreMedicines, medicationList });
    setMedicationList((prevMedicines) => [...prevMedicines, ...moreMedicines]);
    let rxLessDrugsBody = []
    moreMedicines?.forEach(async (x) => {
      let tempSelectedDrug = {
        name: x?.name,
        id: x?.id,
        type: x?.type,
        search_name: x?.search_name,
        dosage: x?.dosage,
        drugSeqNo: x?.drugSeqNo,
        form: x?.form,
        form_plural: x?.form_plural,
        package_size: x?.package_size,
        quantities: x?.quantities,
        default_quantity: x?.default_quantity !== undefined ? x?.default_quantity : JSON.parse(x?.quantities || [])[0],
        names: x?.names,
        ndc: x?.ndc,
        manufacturerOffer: x?.manufactorInfo,
        connectiveRxOffer: x?.crxNetPriceDTO?.crxNetPriceSubSeqNo ? x?.crxNetPriceDTO : undefined

      };
      dispatch(setProgressLock(false));
      dispatch(setResponseState({
        ...responseState,
        rxLess: false,
        rxSense: false, 
        careCard: false,
        prisma: false, 
        copay: false,
        hippo: false
      }));

      dispatch(setProgress(15));
      // dispatch(setProgressLock(false));
      rxLessDrugsBody.push(tempSelectedDrug);
      setHippoPrices(tempSelectedDrug, x);
      setCostPlusDrugPrices(tempSelectedDrug, x);
      setRxSencePrices(tempSelectedDrug, x);
      setCareCardInfo(tempSelectedDrug, x);
    });
    setRxLessPrices(rxLessDrugsBody);

    // setTimeout(() => {
      setMedicationsLength(loadedMedicines + moreMedicines?.length)
    // }, 1000)
  }

  useEffect(() => {
    if (removeDrug?.drugSeqNo) {//drugSeqNo
      let body = {
        appUserSeqNo: user?.appUserSeqNo,
        drugSeqNo: removeDrug?.drugSeqNo,
      };
      let id = removeDrug?.drugSeqNo;
      if (disabledIcons[id]) return;
      setDisabledIcons(prevState => ({ ...prevState, [id]: true }));

      dispatch(deleteMedication(body))
        .unwrap()
        .then((response) => {
          if (response?.status === "OK") {
            setDisabledIcons(prevState => ({ ...prevState, [id]: false }));
            successToastWithId(response?.message, "remove-medications");
            setMedicationInfo((medicationInfo) => medicationInfo?.filter((x) => x?.selectedDrug?.drugSeqNo !== removeDrug?.drugSeqNo));
            setMedicationList((medicationList) => medicationList?.filter((x) => x?.drugSeqNo !== removeDrug?.drugSeqNo));
            setFullMedicineList((medicationList) => medicationList?.filter((x) => x?.drugSeqNo !== removeDrug?.drugSeqNo));
            setHippoDrugsPrices((hippoDrugsPrices) => hippoDrugsPrices?.filter((x) => x?.drugSeqNo !== removeDrug?.drugSeqNo));
            setCostPlusDrugsPrices((costPlusDrugsPrices) => costPlusDrugsPrices?.filter((x) => x?.selectedDrug?.drugSeqNo !== removeDrug?.drugSeqNo));
            setRxSenceDrugsPrices((rxSenceDrugsPrices) => rxSenceDrugsPrices?.filter((x) => x?.drugSeqNo !== removeDrug?.drugSeqNo));
            setCareCardDrugsPrices((careCardDrugsPrices) => careCardDrugsPrices?.filter((x) => x?.drugSeqNo !== removeDrug?.drugSeqNo));
            setRxLessDrugsPrices((rxLessDrugPrices) => rxLessDrugPrices?.filter((x) => x?.drugSeqNo !== removeDrug?.drugSeqNo));
            setCopayPrices((copayPrices) => copayPrices?.filter((x) => x?.drugSeqNo !== removeDrug?.drugSeqNo));

            //remove drug from Prisma Prices List
            setPrismaPrices(prismaPrices?.filter((x) => x?.drugSeqNo !== removeDrug?.drugSeqNo));

            let medi = medicationInfo.filter((x) => x?.selectedDrug?.drugSeqNo !== removeDrug?.drugSeqNo);
            let fullMedi = fullMedicinesList?.filter((x) => x?.drugSeqNo !== removeDrug?.drugSeqNo);
            if (medi?.length == 0 && fullMedi?.length > 0) {
              fetchMoreMedicines();
            }
          }
        })
        .catch((e) => {
          setDisabledIcons(prevState => ({ ...prevState, [id]: false }));
        }).finally((x) => {
        });


    }
  }, [removeDrug])

  useEffect(() => {
    if (showPharmacyMap == true) {
      setMedication({
        drugId: "",
      })
      dispatch(setShowCoupon(false));
    }
  }, [showPharmacyMap])


  useEffect(() => {
    if (user) {
      let body = {
        appUserSeqNo: user?.appUserSeqNo,
      };
      let checkNpi = loggedInUserPharmacy?.userPharmacySeqNo ? loggedInUserPharmacy?.npi : "1801153093";
      if ((checkNpi !== npi)) {
        setNpi(checkNpi);
        dispatch(setFromSearchMap(false))
        dispatch(fetchInsuranceInfo(body)).unwrap().then((response) => {
          if (response?.data?.length > 0) {
            let insuranceObj = {
              "firstName": response.data[0]?.firstName,
              "lastName": response.data[0]?.lastName,
              "date_of_birth": response.data[0]?.dob,
              "gender": response.data[0]?.gender,
              "bin": response.data[0]?.bin,
              "pcn": response.data[0]?.pcn,
              "groupNumber": response.data[0]?.groupNumber,
              "memberId": response.data[0]?.memberId,
              "npi": checkNpi,
              "ndc": "",
              "quantity": "",
              "prescriber_id": "1821039694",
              "state": "",
              "dosage": "",
              "form": "",
              "drugSeqNo": null,
              "drugFullName": "",
              insInfoSeqNo: response.data[0]?.insInfoSeqNo
            };
            dispatch(setInsuranceInfo(response.data[0]))
            setInsuranceDetail((insurance) => insurance.concat(insuranceObj));
          }
          else {
            setHasInsurance(!hasInsurance);
          }

        }).catch((e) => {
          console.log(e);
        }).finally((e) => {
        });
      }
    } else {
      errorToast("404 not found");
      navigate("/");
    }
  }, [loggedInUserPharmacy]);

  const userPharmacy = user?.pharmacy;
  const zip = userPharmacy?.userPharmacySeqNo ? userPharmacy.zipcode : ownPosition?.postalCode ? ownPosition?.postalCode : null;

  useEffect(() => {
    if (pharmacyList.length == 0 && userPharmacy?.userPharmacySeqNo) {
      dispatch(setPharmacyList([userPharmacy]))
    }
  }, [userPharmacy])

  useEffect(() => {
    if (zip) {
      fetchMedications();
      const user = JSON.parse(localStorage.getItem("user"));
      if (user && ownPosition == null) {
        setIp(user?.proxyIpAddress)
      }
      localStorage.removeItem("userBody");
    }
  }, [zip])

  useEffect(() => {
    setSavingOptions(pencilClick);
    const element = document.getElementById("listingContent");
    if (element) {
      element.scrollIntoView();
    }
    if (state?.ndc || state?.rxNorm) {
      setMedication({
        ...medication,
        ndc: state?.ndc,
        rxNorm: state?.rxNorm,
      });
    }
  }, [state]);

  useEffect(() => {
    if (searchDrug) {
      dispatch(searchDrugsByName(searchDrug))
        .unwrap()
        .then((response) => {
          setOptionss(response?.hits);
          dispatch(setDrugsList(response?.hits));
          if (response.hits != 0) {
            ariaAnnounce("Dropdown with suggested matches is opened");
          }
          // if drug not found
          if (response.hits == 0) {
            toast.dismiss();
            errorToast("Medication not found");
            ariaAnnounce("Medication not found");
          }
        })
        .catch((e) => {
        }).finally((x) => {
        });
    }
  }, [searchDrug]);

  useEffect(() => {
    if (medicationList?.length > 0) {
      checkProgress();
      let prismaCheck = hasInsuranceInformation  && prismaPrices?.length == medicationList?.length ?  true : !hasInsuranceInformation ? true : false;
      if (prismaCheck || (rxLessDrugsPrices?.length === medicationList?.length || careCardDrugsPrices?.length === medicationList?.length || rxSenceDrugsPrices?.length === medicationList?.length || hippoDrugsPrices?.length === medicationList?.length)) {
        if (addingMedicine) {
          setAddingMedicine(false)
        }
        if (copayPrices?.length > 0) {
          calculateBestPrices(hippoDrugsPrices, costPlusDrugsPrices, copayPrices, rxSenceDrugsPrices, careCardDrugsPrices, rxLessDrugsPrices, prismaPrices);
        }
        else {
          calculateBestPrices(hippoDrugsPrices, costPlusDrugsPrices, null, rxSenceDrugsPrices, careCardDrugsPrices, rxLessDrugsPrices, prismaPrices);
        }
      }
    }
    else {
      setSumOfBestPrciesAtPharmacies([]);
      setMedicationInfo([]);
    }

  }, [originalFilter, hippoDrugsPrices, costPlusDrugsPrices, copayPrices, prismaPrices, rxSenceDrugsPrices, careCardDrugsPrices, rxLessDrugsPrices])


  const getBestPrices = (copay, hippo, RxSence, careCard, rxLess, prisma) => {

    const getRetailPrice = (uc, price, hippoRetail) => {
      if (uc == 0) {
        return price;
      }
      if (uc > 0) {
        if (uc >= price) return uc;
        else if (uc < price) {
          if (hippoRetail > price) return hippoRetail;
          else return price
        }
      }
    }
    let bestPriceObj = getBestPriceObject(copay, hippo, RxSence, careCard, rxLess, prisma);

    if (copay && bestPriceObj?.isCopay) {
      return {
        //copay;
        estRetailPrice: hippo?.estRetailPrice > 0 ? hippo?.estRetailPrice : copay?.prices?.billing_response?.pricing?.patient_pay / 100,
        bestPrice: copay?.prices?.billing_response?.pricing?.patient_pay / 100,
        description: "with insurance",
        hasInsurance: true,
        rxSense: false,
        isCareCard: false,
        isHippo: false,
        isRxless: false,
        InsuranceInfo: {
          bin: copay?.bin,
          pcn: copay?.pcn,
          ID: copay?.memberId,
          Group: copay?.groupNumber
        },
        careCardInfo: {}
      }
    }
    else if (prisma && bestPriceObj?.isPrisma) {
      return {
        // prisma;
        estRetailPrice: hippo?.estRetailPrice > 0 ? hippo?.estRetailPrice : copay?.prices?.billing_response?.pricing?.patient_pay / 100,
        bestPrice: parseFloat(prisma?.price),
        description: "with insurance",
        hasInsurance: true,
        rxSense: false,
        isCareCard: false,
        isHippo: false,
        isRxless: false,
        isPrisma: true,
        InsuranceInfo: {
          bin: copay?.bin,
          pcn: copay?.pcn,
          ID: copay?.memberId,
          Group: copay?.groupNumber
        },
        careCardInfo: {}
      }
    }
    else if (hippo && bestPriceObj?.isHippo) {
      return {
        // hippo;
        estRetailPrice: hippo?.estRetailPrice > 0 ? hippo?.estRetailPrice : copay?.prices?.billing_response?.pricing?.patient_pay / 100,
        bestPrice: hippo?.cost,
        description: "with discount card",
        hasInsurance: false,
        rxSense: false,
        isCareCard: false,
        isHippo: true,
        isRxless: false,
        InsuranceInfo: {

        },
        careCardInfo: {}
      }
    }
    else if (RxSence && bestPriceObj?.isRxSence) {
      return {
        //RxSence;
        estRetailPrice: getRetailPrice(RxSence?.Prices[0]?.UsualAndCustomary, RxSence?.Prices[0]?.Price, hippo?.estRetailPrice),
        //RxSence?.Prices[0]?.UsualAndCustomary > 0 ? RxSence?.Prices[0]?.UsualAndCustomary : RxSence?.Prices[0]?.Price,
        bestPrice: RxSence?.Prices[0]?.Price,
        description: "with Single Care",
        hasInsurance: false,
        rxSense: true,
        isCareCard: false,
        isHippo: false,
        isRxless: false,
        InsuranceInfo: {

        },
        careCardInfo: {}
      }
    }
    else if (careCard && bestPriceObj?.isCareCard) {
      return {
        //CareCard;
        estRetailPrice: careCard?.priceBeforeDiscount ? careCard?.priceBeforeDiscount : 0,
        bestPrice: careCard?.price,
        description: "with Care Card",
        hasInsurance: false,
        rxSense: false,
        isCareCard: true,
        isHippo: false,
        isRxless: false,
        InsuranceInfo: {

        },
        careCardInfo: {
          bin: careCard?.bin,
          groupId: careCard?.groupId,
          memberId: careCard?.memberId,
          pcn: careCard?.pcn,
          ndc: careCard?.ndc
        }
      }
    }

    else if (rxLess && bestPriceObj?.isRxLess) {
      return {
        //CareCard;
        estRetailPrice: getRetailPrice(0, rxLess?.price, hippo?.estRetailPrice),
        //RxSence?.Prices[0]?.UsualAndCustomary > 0 ? RxSence?.Prices[0]?.UsualAndCustomary : RxSence?.Prices[0]?.Price,
        bestPrice: rxLess?.price,
        description: "with RxLess",
        hasInsurance: false,
        rxSense: false,
        isCareCard: false,
        isHippo: false,
        isRxless: true,
        InsuranceInfo: {

        },
        careCardInfo: {
          bin: rxLess?.bin,
          groupId: rxLess?.groupId,
          memberId: rxLess?.memberId,
          pcn: rxLess?.pcn,
          ndc: rxLess?.ndc
        }
      }
    }
  }

  const getNormalizedPrice = (object) => {
    if (object?.prices?.billing_response?.pricing?.patient_pay / 100 >= 0) return object?.prices?.billing_response?.pricing?.patient_pay / 100;
    if (object?.cost > 0) return object.cost;
    if (object?.Prices?.[0].Price > 0) return object?.Prices[0].Price;
    if (object?.price >= 0) return object.price;
    return Infinity; // If no price property is found, return a high value to exclude it
  };

  const getBestPriceObject = (copay, hippo, rxSence, careCard, rxLess, prisma) => {

    const normalizedPrice1 = getNormalizedPrice(copay);
    const normalizedPrice2 = getNormalizedPrice(hippo);
    const normalizedPrice3 = getNormalizedPrice(rxSence);
    const normalizedPrice4 = getNormalizedPrice(careCard);
    const normalizedPrice5 = getNormalizedPrice(rxLess);
  
    let bestPriceObject = {};
    let minPrice = Infinity;
  
    // Check Copay first
    if (normalizedPrice1 && normalizedPrice1 < minPrice) {
      bestPriceObject = { ...copay, isCopay: true };
      minPrice = normalizedPrice1;
    }
  
    // Create an array of offers and sort according to price and priority
    const offers = [
      { price: normalizedPrice3, object: { ...rxSence, isRxSence: true }, provider: 'RxSense' },
      { price: normalizedPrice2, object: { ...hippo, isHippo: true }, provider: 'Hippo' },
      { price: normalizedPrice5, object: { ...rxLess, isRxLess: true }, provider: 'RxLess' },
      { price: normalizedPrice4, object: { ...careCard, isCareCard: true }, provider: 'CareCard' }
    ];
  
    // Filter out invalid offers (price is undefined or null)
    const validOffers = offers.filter(offer => offer?.price != null);
  
    // Sort offers by price and then by priority based on your priority order
    validOffers.sort((a, b) => {
      if (a.price !== b.price) {
        return a.price - b.price; // Sort by price first
      }
      // Sort by priority if prices are equal
      const priorityOrder = ['RxSense', 'Hippo', 'RxLess', 'CareCard'];
      return priorityOrder.indexOf(a.provider) - priorityOrder.indexOf(b.provider);
    });
  
    // Check the first valid offer with the lowest price
    if (validOffers.length > 0 && validOffers[0].price < minPrice) {
      bestPriceObject = validOffers[0].object;
      minPrice = validOffers[0].price;
    }
  
    // Finally, check Prisma if provided
    if (prisma?.price != null && parseFloat(prisma?.price) < minPrice) {
      bestPriceObject = { ...prisma, prismaPrice: parseFloat(prisma?.price), isPrisma: true };
      minPrice = parseFloat(prisma?.price);
    }
  
    return bestPriceObject;
  };

  const getUniquePharmacies = (hippo, rxSense, careCard, rxLess) => {
    if (!hippo && !rxSense && !careCard && !rxLess) return [];
    let mappedHippo, mappedRx, mappedCareCard, mappedRxLess;
    if (hippo) {
      mappedHippo = hippo?.map((pharmacy) => mapUniqueHippoToHippo(pharmacy))
    }
    if (rxSense) {
      mappedRx = rxSense?.map((pharmacy) => mapUniqueRxToHippo(pharmacy))
    }

    if (careCard) {
      mappedCareCard = careCard?.map((pharmacy) => mapUniqueCareCardToHippo(pharmacy))
    }

    if (rxLess) {
      mappedRxLess = rxLess?.map((pharmacy) => mapUniqueRxLessToHippo(pharmacy))
    }

    const getUniqueObjects = (array, propertyName) => {
      return array.filter((item, index, self) =>
        index === self.findIndex((t) => t[propertyName] === item[propertyName])
      );
    }
    const pharmacies = [mappedHippo, mappedRx, mappedCareCard, mappedRxLess];
    // Filter out null or undefined pharmacies
    const validPharmacies = pharmacies.filter(arr => arr != null || arr != undefined);

    // Combine all valid pharmacies into one
    const combinedValidPharmacies = [].concat(...validPharmacies);
    const uniquePharmacies = getUniqueObjects(combinedValidPharmacies, "chain");
    return [...uniquePharmacies];
  }

  const getDrugsArray = (array, index, drug, pharmacyInfo) => {

    console.log(array[index]?.drugs?.map(item => item?.selectedDrug?.drugSeqNo));
    let ind = array[index]?.drugs?.map(item => item?.selectedDrug?.drugSeqNo)?.indexOf(drug?.selectedDrug?.drugSeqNo);
    console.log(ind === -1 ? 0 : ind);

    console.log(array[index]?.drugs.splice(array[index]?.drugs?.map(item => item?.selectedDrug?.drugSeqNo)?.indexOf(drug?.selectedDrug?.drugSeqNo) === -1 ? 0 : array[index]?.drugs?.map(item => item?.selectedDrug?.drugSeqNo)?.indexOf(drug?.selectedDrug?.drugSeqNo), array[index]?.drugs?.map(item => item?.selectedDrug?.drugSeqNo)?.indexOf(drug?.selectedDrug?.drugSeqNo) === -1 ? 0 : 1, drug));
    console.log({ array: array[index]?.drugs, pharmacyInfo });

    let response = array[index]?.drugs.map((pharma, index) => {
      return {
        ...pharma,
        chain: pharmacyInfo?.chain,
        pharmacyName: pharmacyInfo?.pharmacyName,
        logo: pharmacyInfo?.logo
      }
    })
    return response;
  }

  function calculateBestPrices(hippoDrugsPrices, costPlusDrugsPrices, copayPrices, rxSenceDrugsPrices, careCardDrugsPrices, rxLessDrugPrices, prismaPrices) {
    let array = [];
    // Function to find an element by drugSeqNo in an array
    function findByDrugSeqNo(arr, drugSeqNo) {
      return arr?.find(item => item?.selectedDrug?.drugSeqNo === drugSeqNo);
    }

    // set medicationInfo empty in case there are some old records still exist
    setMedicationInfo([]);

    // Iterate through medications list
    medicationList?.forEach((med) => {

      let element = {
        ...med,
        selectedDrug: {
          ...med,
          connectiveRxOffer: med?.crxNetPriceDTO?.crxNetPriceSubSeqNo ? med?.crxNetPriceDTO : undefined,
          manufacturerOffer: med?.manufactorInfo
      }
    }
      let hippo = findByDrugSeqNo(hippoDrugsPrices, element?.drugSeqNo);

      // Find corresponding item in costPlusDrugsPrices
      let sub_element = findByDrugSeqNo(costPlusDrugsPrices, element?.drugSeqNo);
      // if (!sub_element) return;

      // Find corresponding item in rxSenceDrugsPrices
      let ele = findByDrugSeqNo(rxSenceDrugsPrices, element?.drugSeqNo);
      // if (!ele) return;

      // Find corresponding item in careCardDrugsPrices
      let careCardEle = findByDrugSeqNo(careCardDrugsPrices, element?.drugSeqNo);
      // if (!careCardEle) return;

      // Find corresponding item in rxLessDrugPrices
      let rxLess = findByDrugSeqNo(rxLessDrugPrices, element?.drugSeqNo);
      // if (!rxLess) return;

      // Process copayPrices
      let tempCopayArr = copayPrices?.filter(x => x?.drugSeqNo === element?.drugSeqNo);
      // if (tempCopayArr?.length === 0) return;

      let tempPrismaArr = prismaPrices?.filter(x => x?.drugSeqNo === element?.drugSeqNo);

      // Process other data
      let selectedPharmacy = normalizeCompanyName(originalFilter?.[0]?.chain) ? normalizeCompanyName(originalFilter?.[0]?.chain) : normalizeCompanyName(user?.pharmacy?.pharmacyName?.toLowerCase());
      let temphippoPrice1 = hippo?.drugPrices?.filter(x => normalizeCompanyName(x?.chain) === selectedPharmacy);
      let tempRxSencePrice1 = ele?.rxsenseResponse?.Value?.PharmacyPricings?.filter(x => normalizeCompanyName(x?.Pharmacy.Name) == selectedPharmacy);
      let tempCareCardPrice1 = careCardEle?.careCardResponse?.filter(x => normalizeCompanyName(x?.chain) == selectedPharmacy);
      let tempRxLessPrice = rxLess?.rxLessResponse?.filter(x => normalizeCompanyName(x?.chain) == selectedPharmacy);
      let res = getUniquePharmacies(hippo?.drugPrices, ele?.rxsenseResponse?.Value?.PharmacyPricings, careCardEle?.careCardResponse, rxLess?.rxLessResponse);

      let bestCostObj1 = getBestPrices(
        tempCopayArr?.[0],
        temphippoPrice1?.[0],
        tempRxSencePrice1?.[0],
        tempCareCardPrice1?.[0],
        tempRxLessPrice?.[0],
        tempPrismaArr?.[0]
      );

      // Construct tempMedArr1
      let tempMedArr1 = [{
        drugSeqNo: element?.drugSeqNo,
        selectedDrug: element?.selectedDrug,
        selectedQty: element?.selectedDrug?.default_quantity,
        selectedLabel: element?.selectedDrug?.name,
        selectedForm: element?.selectedDrug?.form,
        selectedDosage: element?.selectedDrug?.dosage,
        isExpandable: false,
        costPlusDrugPrice: sub_element?.costPlusDrugPrice,
        estRetailPrice: bestCostObj1?.estRetailPrice || 0,
        bestPrice: bestCostObj1?.bestPrice || 0,
        patient_pay: tempCopayArr?.[0]?.prices?.billing_response?.pricing?.patient_pay >= 0 ? tempCopayArr?.[0]?.prices?.billing_response?.pricing?.patient_pay : 0,
        amount_of_copay: tempCopayArr?.[0]?.prices?.billing_response?.pricing?.amount_of_copay >= 0 ? tempCopayArr?.[0]?.prices?.billing_response?.pricing?.amount_of_copay : 0,
        hippoPrice: temphippoPrice1?.[0]?.cost,
        rxSensePrice: tempRxSencePrice1?.[0]?.Prices?.[0]?.Price,
        careCardPrice: tempCareCardPrice1?.[0]?.price,
        rxLessPrice: tempRxLessPrice?.[0]?.price,
        chain: temphippoPrice1?.[0]?.chain ? temphippoPrice1?.[0]?.chain.toLowerCase() : tempRxSencePrice1?.[0]?.Pharmacy?.Name ? tempRxSencePrice1?.[0]?.Pharmacy?.Name.toLowerCase() : tempCareCardPrice1?.[0]?.chain ? tempCareCardPrice1?.[0]?.chain.toLowerCase() : tempRxLessPrice?.[0]?.chain.toLowerCase(),
        logo: temphippoPrice1?.[0]?.logo,
        pharmacyName: temphippoPrice1?.[0]?.pharmacyName,
        description: bestCostObj1?.description,
        hasInsurance: bestCostObj1?.hasInsurance,
        rxSense: bestCostObj1?.rxSense,
        InsuranceInfo: bestCostObj1?.InsuranceInfo,
        careCardInfo: bestCostObj1?.careCardInfo,
        isHippo: bestCostObj1?.isHippo,
        isCareCard: bestCostObj1?.isCareCard,
        isRxLess: bestCostObj1?.isRxless,
        prismaDrugPrice: tempPrismaArr?.[0]?.price != null ? tempPrismaArr?.[0]?.price : null
      }];

      // Process res (assuming it comes from getUniquePharmacies)
      res?.forEach((drugPr) => {
        let temphippoPrice = hippo?.drugPrices?.filter(x => normalizeCompanyName(x?.chain) === drugPr?.chain);
        let tempRxSencePrice = ele?.rxsenseResponse?.Value?.PharmacyPricings?.filter(x => normalizeCompanyName(x?.Pharmacy.Name) == drugPr?.pharmacyName);
        let tempCareCardPrice = careCardEle?.careCardResponse?.filter(x => normalizeCompanyName(x?.chain) == drugPr?.chain);
        let tempRxLessPrice = rxLess?.rxLessResponse?.filter(x => normalizeCompanyName(x?.chain) == drugPr?.chain);

        let bestCostObj = getBestPrices(
          tempCopayArr?.[0],
          temphippoPrice?.[0],
          tempRxSencePrice?.[0],
          tempCareCardPrice?.[0],
          tempRxLessPrice?.[0],
          tempPrismaArr?.[0]
        );

        let tempMedArr = [{
          selectedDrug: element?.selectedDrug,
          selectedQty: element?.selectedDrug?.default_quantity,
          selectedLabel: element?.selectedDrug?.name,
          selectedForm: element?.selectedDrug?.form,
          selectedDosage: element?.selectedDrug?.dosage,
          drugSeqNo: element?.drugSeqNo,
          isExpandable: false,
          costPlusDrugPrice: sub_element?.costPlusDrugPrice,
          estRetailPrice: bestCostObj?.estRetailPrice || 0,
          bestPrice: bestCostObj?.bestPrice || 0,
          patient_pay: tempCopayArr?.[0]?.prices?.billing_response?.pricing?.patient_pay >= 0 ? tempCopayArr?.[0]?.prices?.billing_response?.pricing?.patient_pay : 0,
          amount_of_copay: tempCopayArr?.[0]?.prices?.billing_response?.pricing?.amount_of_copay >= 0 ? tempCopayArr?.[0]?.prices?.billing_response?.pricing?.amount_of_copay : 0,
          hippoPrice: temphippoPrice?.[0]?.cost,
          rxSensePrice: tempRxSencePrice?.[0]?.Prices?.[0]?.Price,
          careCardPrice: tempCareCardPrice?.[0]?.price,
          rxLessPrice: tempRxLessPrice?.[0]?.price,
          chain: drugPr?.chain,
          logo: drugPr?.logo,
          pharmacyName: drugPr?.pharmacyName,
          description: bestCostObj?.description,
          hasInsurance: bestCostObj?.hasInsurance,
          rxSense: bestCostObj?.rxSense,
          isCareCard: bestCostObj?.isCareCard,
          hasDiscount: bestCostObj?.isHippo ? true : false,
          InsuranceInfo: {
            bin: tempCopayArr?.[0]?.bin,
            pcn: tempCopayArr?.[0]?.pcn,
            ID: tempCopayArr?.[0]?.memberId,
            Group: tempCopayArr?.[0]?.groupNumber
          },
          prismaDrugPrice: tempPrismaArr?.[0]?.price != null ? tempPrismaArr?.[0]?.price : null
        }];
        // Update array with new or merged data
        if (array.length > 0) {
          let i = array.findIndex(x => x?.chain === drugPr?.chain);
          if (i !== -1) {
            let updatedObj = {
              drugSeqNo: tempMedArr[0]?.drugSeqNo,
              drugs: getDrugsArray(array, i, tempMedArr[0], drugPr),
              costPlusDrugPrice: sub_element?.costPlusDrugPrice,
              estRetailPrice: array[i]?.estRetailPrice + tempMedArr[0]?.estRetailPrice,
              bestPrice: array[i]?.bestPrice + tempMedArr[0]?.bestPrice,
              patient_pay: tempMedArr[0]?.hasInsurance ? tempCopayArr?.[0]?.prices?.billing_response?.pricing?.patient_pay || 0 : 0,
              amount_of_copay: tempMedArr[0]?.hasInsurance ? tempCopayArr?.[0]?.prices?.billing_response?.pricing?.amount_of_copay || 0 : 0,
              hippoPrice: drugPr?.cost,
              rxSensePrice: drugPr?.rx?.Prices[0].Price,
              careCardPrice: tempMedArr[0]?.careCardPrice,
              rxLessPrice: tempMedArr[0]?.rxLessPrice,
              chain: drugPr?.chain,
              logo: drugPr?.logo,
              pharmacyName: drugPr?.pharmacyName,
              description: tempMedArr[0]?.description,
              hasInsurance: tempMedArr[0]?.hasInsurance,
              rxSense: tempMedArr[0]?.rxSense,
              isCareCard: tempMedArr[0]?.isCareCard,
              hasDiscount: tempMedArr[0]?.isHippo ? true : false,
              InsuranceInfo: {
                bin: tempMedArr[0]?.hasInsurance ? tempCopayArr?.[0]?.bin : "",
                pcn: tempMedArr[0]?.hasInsurance ? tempCopayArr?.[0]?.pcn : "",
                ID: tempMedArr[0]?.hasInsurance ? tempCopayArr?.[0]?.memberId : "",
                Group: tempMedArr[0]?.hasInsurance ? tempCopayArr?.[0]?.groupNumber : ""
              },
              prismaDrugPrice: tempPrismaArr?.[0]?.price != null ? tempPrismaArr?.[0]?.price : null
            };
            array = [
              ...array.slice(0, i),
              Object.assign({}, array[i], updatedObj),
              ...array.slice(i + 1)
            ];
          } else {
            array.push({
              drugSeqNo: tempMedArr[0]?.drugSeqNo,
              drugs: tempMedArr,
              costPlusDrugPrice: sub_element?.costPlusDrugPrice,
              estRetailPrice: tempMedArr[0]?.estRetailPrice,
              bestPrice: tempMedArr[0]?.bestPrice,
              patient_pay: tempMedArr[0]?.hasInsurance ? tempCopayArr?.[0]?.prices?.billing_response?.pricing?.patient_pay || 0 : 0,
              amount_of_copay: tempMedArr[0]?.hasInsurance ? tempCopayArr?.[0]?.prices?.billing_response?.pricing?.amount_of_copay || 0 : 0,
              hippoPrice: drugPr?.cost,
              rxSensePrice: drugPr?.rx?.Prices[0].Price,
              careCardPrice: tempMedArr[0]?.careCardPrice,
              rxLessPrice: tempMedArr[0]?.rxLessPrice,
              chain: drugPr?.chain,
              logo: drugPr?.logo,
              pharmacyName: drugPr?.pharmacyName,
              description: tempMedArr[0]?.description,
              hasInsurance: tempMedArr[0]?.hasInsurance,
              rxSense: tempMedArr[0]?.rxSense,
              isCareCard: tempMedArr[0]?.isCareCard,
              hasDiscount: tempMedArr[0]?.isHippo ? true : false,
              InsuranceInfo: {
                bin: tempMedArr[0]?.hasInsurance ? tempCopayArr?.[0]?.bin : "",
                pcn: tempMedArr[0]?.hasInsurance ? tempCopayArr?.[0]?.pcn : "",
                ID: tempMedArr[0]?.hasInsurance ? tempCopayArr?.[0]?.memberId : "",
                Group: tempMedArr[0]?.hasInsurance ? tempCopayArr?.[0]?.groupNumber : ""
              },
              prismaDrugPrice: tempPrismaArr?.[0]?.price != null ? tempPrismaArr?.[0]?.price : null
            });
          }
        } else {
          array.push({
            drugSeqNo: tempMedArr[0]?.drugSeqNo,
            drugs: tempMedArr,
            costPlusDrugPrice: sub_element?.costPlusDrugPrice,
            estRetailPrice: tempMedArr[0]?.estRetailPrice,
            bestPrice: tempMedArr[0]?.bestPrice,
            patient_pay: tempMedArr[0]?.hasInsurance ? tempCopayArr?.[0]?.prices?.billing_response?.pricing?.patient_pay || 0 : 0,
            amount_of_copay: tempMedArr[0]?.hasInsurance ? tempCopayArr?.[0]?.prices?.billing_response?.pricing?.amount_of_copay || 0 : 0,
            hippoPrice: drugPr?.cost,
            rxSensePrice: drugPr?.rx?.Prices[0].Price,
            careCardPrice: tempMedArr[0]?.careCardPrice,
            rxLessPrice: tempMedArr[0]?.rxLessPrice,
            chain: drugPr?.chain,
            logo: drugPr?.logo,
            pharmacyName: drugPr?.pharmacyName,
            description: tempMedArr[0]?.description,
            hasInsurance: tempMedArr[0]?.hasInsurance,
            rxSense: tempMedArr[0]?.rxSense,
            isCareCard: tempMedArr[0]?.isCareCard,
            hasDiscount: tempMedArr[0]?.isHippo ? true : false,
            InsuranceInfo: {
              bin: tempMedArr[0]?.hasInsurance ? tempCopayArr?.[0]?.bin : "",
              pcn: tempMedArr[0]?.hasInsurance ? tempCopayArr?.[0]?.pcn : "",
              ID: tempMedArr[0]?.hasInsurance ? tempCopayArr?.[0]?.memberId : "",
              Group: tempMedArr[0]?.hasInsurance ? tempCopayArr?.[0]?.groupNumber : ""
            },
            prismaDrugPrice: tempPrismaArr?.[0]?.price != null ? tempPrismaArr?.[0]?.price : null
          });
        }
      });

      // Set state or update values as needed
      setSumOfBestPrciesAtPharmacies(array.sort((a, b) => a?.bestPrice - b?.bestPrice));
      setMedicationInfo(medicationInfo => medicationInfo?.filter((x, i) => x?.selectedDrug?.drugSeqNo !== element?.selectedDrug?.drugSeqNo).concat(tempMedArr1));
    });

    // Further logic after processing array
    let userLoginResPharmacy = user?.pharmacy;
    let allApisCalled = rxLessDrugsPrices?.length === medicationList?.length && careCardDrugsPrices?.length === medicationList?.length && rxSenceDrugsPrices?.length === medicationList?.length && hippoDrugsPrices?.length === medicationList?.length;
    if (array?.length > 0) {
      if (selectMapValues?.length === 0 || !allApisCalled) {
        if (userLoginResPharmacy?.pharmacyName) {
          setSelectMapValues(userLoginResPharmacy)
        }
        else
          setSelectMapValues(array
            .filter(item => item.bestPrice > 0 || item.hasInsurance)
            .sort((a, b) => a.bestPrice - b.bestPrice)[0]);
      } else {
        let checkArray = array.filter(item => item?.pharmacyName?.toLowerCase() == selectMapValues?.pharmacyName?.toLowerCase());
        if (checkArray.length === 0) {
          if (selectMapValues?.pharmacyName?.toLowerCase() !== userLoginResPharmacy?.pharmacyName?.toLowerCase()) {
            setSelectMapValues(array.sort((a, b) => a?.bestPrice - b?.bestPrice)[0]);
          }
        }
      }
    }
    else{
      if (selectMapValues?.length === 0 || !allApisCalled) {
        if (userLoginResPharmacy?.pharmacyName) {
          setSelectMapValues(userLoginResPharmacy);
        }
      }
    }
  }

  // set the loading progress bar based on API responses
  const checkProgress = () => {
    if (progress > 0 && progress < 100 && !progressLock) {
      if (rxLessDrugsPrices?.length === medicationList?.length && !responseState?.rxLess) {
        dispatch(setProgress(progress + 20));
        dispatch(setResponseState({
          ...responseState,
          rxLess: true
        }));
      }
      if (rxSenceDrugsPrices?.length === medicationList?.length && !responseState?.rxSense) {
        dispatch(setProgress(progress + 15));
        dispatch(setResponseState({
          ...responseState,
          rxSense: true
        }));
      }
      if (hippoDrugsPrices?.length === medicationList?.length && !responseState?.hippo) {
        dispatch(setProgress(progress + 15));
        dispatch(setResponseState({
          ...responseState,
          hippo: true
        }));
      }
      if (careCardDrugsPrices?.length === medicationList?.length && !responseState?.careCard) {
        dispatch(setProgress(progress + 15));
        dispatch(setResponseState({
          ...responseState,
          careCard: true
        }));
      }
      if ((prismaPrices?.length === medicationList?.length && !responseState?.prisma) || !hasInsuranceInformation ) {
        dispatch(setProgress(progress + 10));
        dispatch(setResponseState({
          ...responseState,
          prisma: true
        }));
      }
      if ((copayPrices?.length === medicationList?.length && !responseState?.copay) || !hasInsuranceInformation) {
        dispatch(setProgress(progress + 10));
        dispatch(setResponseState({
          ...responseState,
          copay: true
        }));
      }
    }
    if (progress >= 100) {
      dispatch(setProgress(100));
      dispatch(setProgressLock(true));
    }
  }

  useEffect(() => {
    let finalArray = [];
    // set Description for pharmacies list
    if (sumOfBestPrciesAtPharmacies?.length > 0 && originalFilter?.length > 0) {
      let topTenPharmacies = [];
      let selectTopPharmacies = sumOfBestPrciesAtPharmacies.filter((x, i) => (x?.bestPrice > 0 || x?.hasInsurance)).sort((a, b) => a?.bestPrice - b?.bestPrice).slice(0, 10);

      let ind = selectTopPharmacies?.findIndex((pharmacy) => pharmacy?.chain == normalizeCompanyName(originalFilter?.[0]?.chain));
      if (ind == -1) {
        // Get the pharmacies that match the original filter
        const matchingPharmacies = sumOfBestPrciesAtPharmacies.filter(x => x?.chain === normalizeCompanyName(originalFilter?.[0]?.chain));

        // Get the top 9 pharmacies with the best prices greater than 0
        const topPharmacies = sumOfBestPrciesAtPharmacies.filter(x => (x?.bestPrice > 0 || x?.hasInsurance))
          .sort((a, b) => a?.bestPrice - b?.bestPrice)
          .slice(0, 9);

        // Concatenate both arrays
        topTenPharmacies = [...matchingPharmacies, ...topPharmacies];

        // Sort the final array again to ensure correct order
        topTenPharmacies = topTenPharmacies.filter(x => (x?.bestPrice > 0 || x?.hasInsurance))
          .sort((a, b) => a?.bestPrice - b?.bestPrice);

      }
      else {
        topTenPharmacies = selectTopPharmacies = sumOfBestPrciesAtPharmacies.filter((x, i) => (x?.bestPrice > 0 || x?.hasInsurance)).sort((a, b) => a?.bestPrice - b?.bestPrice).slice(0, 10);
      }

      sumOfBestPrciesAtPharmacies?.filter(x => (x?.bestPrice > 0 || x?.hasInsurance))?.map((element) => {
        let rxDrugs = element?.drugs?.filter((x, i) => x?.rxSense);
        let copayDrugs = element?.drugs?.filter((x, i) => x?.hasInsurance);
        let description = "";
        if (copayDrugs?.length === 0 && rxDrugs?.length === 0) {
          finalArray.push(
            {
              ...element,
              description: "with discount card"
            }
          );
        }
        else if (copayDrugs?.length === 0 && (rxDrugs?.length > 0 && rxDrugs?.length < element?.drugs?.length)) {
          finalArray.push(
            {
              ...element,
              description: "with RxLink Offer"
            }
          );
        }
        else if (copayDrugs?.length === 0 && (rxDrugs?.length > 0 && rxDrugs?.length === element?.drugs?.length)) {
          finalArray.push(
            {
              ...element,
              description: "with Single Care"
            }
          );
        }
        else if (rxDrugs?.length === 0 && (copayDrugs?.length > 0 && copayDrugs?.length < element?.drugs?.length)) {
          finalArray.push(
            {
              ...element,
              description: "with RxLink Offer"
            }
          );
        }
        else if (rxDrugs?.length === 0 && (copayDrugs?.length > 0 && copayDrugs?.length === element?.drugs?.length)) {
          finalArray.push(
            {
              ...element,
              description: "with insurance"
            }
          );
        }
        else if (copayDrugs?.length > 0 && rxDrugs?.length > 0 && copayDrugs?.length < element?.drugs?.length && rxDrugs?.length < element?.drugs?.length) {
          finalArray.push(
            {
              ...element,
              description: "with RxLink Offer"
            }
          );
        }
      });
      let brandedPharamcies = [];
      let localPharmacies = [];
      finalArray?.map((pharma) => {
        if (searchPharmacyType(pharma.pharmacyName).type == "local") localPharmacies.push(pharma);
        else brandedPharamcies.push(pharma);
      });
      let combined = [
        ...brandedPharamcies,
        ...(localPharmacies.length > 0
          ? [{
            ...localPharmacies[0],
            pharmacyName: "local",
            chain: "local",
            pharmaciesList: localPharmacies.filter(x => (x?.bestPrice > 0 || x?.hasInsurance))
          }]
          : [])
      ];
      setBestPackage(combined.sort((a, b) => a?.bestPrice - b?.bestPrice));
      if (isLoader) {
        if (rxLessDrugsPrices.length === medicationList.length || rxSenceDrugsPrices?.length === medicationList?.length || hippoDrugsPrices?.length === medicationList?.length || careCardDrugsPrices?.length === medicationList?.length) {

          setIsLoader(false);
          dispatch(setAnimatedLoading(false));
          if (isUpdatingPharmacy) {
            dispatch(setIsUpdatingPhamracy(false))
            if (isRemovingPharmacy) {
              dispatch(setIsRemovingPharmacy(false))
            }
            else {
              dispatch(setShowPahrmacyMap(false));
              dispatch(setFromDrawer(false));
            }
          }
        }
      }
    }
    else {
      setBestPackage([]);

      if (isLoader) {
        if (rxLessDrugsPrices.length === medicationList.length || rxSenceDrugsPrices?.length === medicationList?.length || hippoDrugsPrices?.length === medicationList?.length || careCardDrugsPrices?.length === medicationList?.length) {
          setIsLoader(false);
          dispatch(setAnimatedLoading(false));
          if (isUpdatingPharmacy) {
            dispatch(setIsUpdatingPhamracy(false))
            if (isRemovingPharmacy) {
              dispatch(setIsRemovingPharmacy(false))
            }
            else {
              dispatch(setShowPahrmacyMap(false));
              dispatch(setFromDrawer(false));
            }
          }
        }
      }
    }
  }, [sumOfBestPrciesAtPharmacies, isLoader])

  useEffect(() => {
    localStorage.removeItem("userBody");
    if (hasInsuranceInformation || medicationList?.length == 0) {
      dispatch(setDisclaimer([]));
    }
    if (medicationList?.length > 0 && hasInsuranceInformation) {
      let checkNpi = loggedInUserPharmacy?.userPharmacySeqNo ? loggedInUserPharmacy?.npi : "1801153093";

      let tempArr = [];
      medicationList.forEach((x) => {
        let tempSelectedDrug = {
          name: x?.name,
          id: +x?.id,
          type: x?.type,
          search_name: x?.search_name,
          dosage: x?.dosage,
          drugSeqNo: x?.drugSeqNo,
          form: x?.form,
          form_plural: x?.form_plural,
          package_size: x?.package_size,
          quantities: x?.quantities,
          default_quantity: x?.default_quantity !== undefined ? x?.default_quantity : x?.quantities[0],
          names: x?.names,
          ndc: x?.ndc,
        };
        tempArr.push({

          ndc: tempSelectedDrug?.ndc,
          quantity: tempSelectedDrug?.default_quantity,
          state: ownPosition?.responseZIP?.data?.region_code,
          dosage: tempSelectedDrug?.dosage,
          form: tempSelectedDrug?.form,
          drugSeqNo: tempSelectedDrug?.drugSeqNo,
          drugFullName: tempSelectedDrug?.search_name,
          "firstName": patientInsuranceInfo?.firstName,
          "lastName": patientInsuranceInfo?.lastName,
          "date_of_birth": patientInsuranceInfo?.dob,
          "gender": patientInsuranceInfo?.gender,
          "bin": patientInsuranceInfo?.bin,
          "pcn": patientInsuranceInfo?.pcn,
          "groupNumber": patientInsuranceInfo?.groupNumber,
          "memberId": patientInsuranceInfo?.memberId,
          "npi": checkNpi,
          "prescriber_id": JSON.parse(localStorage.getItem("user"))?.npi,
        });
      });

      let body = {
        appUserSeqNo: JSON.parse(localStorage.getItem("user"))?.appUserSeqNo,
        state: userHasPharmacy ? userPharmacy?.state : ownPosition?.responseZIP?.region_code,
        copayDrugInfo: tempArr
      }
      dispatch(fetchCopayPrices(body))
        .unwrap()
        .then((res) => {
          if (res?.data?.length > 0) {
            setCopayPrices(res?.data);
          }
        });

      dispatch(fetchPrismaRules(body))
        .unwrap()
        .then((res) => {
          if (res?.data?.length > 0) {
            setPrismaPrices(res?.data);
            // setCopayPrices(res?.data);
            let checkDisclaimer = [];
            res?.data.map((item) => {
              if (item?.portalText?.length > 0) {
                checkDisclaimer.push(item?.portalText?.[0])
              }
            })
            if (checkDisclaimer?.length > 0) {
              dispatch(setDisclaimer([checkDisclaimer?.[0]]))
            }
            else {
              dispatch(setDisclaimer([]))
            }
          }
        });
    }
  }, [patientInsuranceInfo, medicationList]);

  async function fetchMedications() {

    dispatch(resetProgressOperation("reset"))
    const userHasInsurance = JSON.parse(localStorage.getItem("user"))?.hasInsurance;
    let body = null;
    if (userHasInsurance) {
      body = {
        appUserSeqNo: JSON.parse(localStorage.getItem("user"))?.appUserSeqNo,
        dob: JSON.parse(localStorage.getItem("user"))?.dob || "",
        pharmacyState: userHasPharmacy ? userPharmacy?.state : ownPosition?.responseZIP?.region_code
      };
    }
    else {
      body = {
        appUserSeqNo: JSON.parse(localStorage.getItem("user"))?.appUserSeqNo,
      };
    }

    localStorage.removeItem("userBody");

    dispatch(fetchMedicationList(body))
      .unwrap()
      .then((response) => {
        setIsLoader(true);
        if (response?.data?.length > 0) {
          dispatch(setProgress(15));
          const existingLoadedMeds = () => {
            let meds = [];
            let moreMeds = [];
            if (medicationList.length > 10) {
              response?.data?.map((med) => {
                let medication = medicationList.filter((item) => item?.drugSeqNo == med?.drugSeqNo);
                if (medication?.length > 0) meds.push(medication[0]);
                else moreMeds.push(med);
              })
            }
            else {
              return { meds: response?.data?.slice(0, 10), moreMeds: response?.data?.slice(10, 50) };
            }
            return { meds: meds, moreMeds: moreMeds };
          }
          let medicinesList = response?.data?.length > 10 ? existingLoadedMeds()?.meds : response?.data;
          if (response?.data?.length > 0) {
            setFullMedicineList([...existingLoadedMeds()?.meds, ...existingLoadedMeds()?.moreMeds]);
          }
          setMedicationsLength(medicinesList?.length);
          setMedicationList(medicinesList);
          setHippoDrugsPrices([]);
          setRxLessDrugsPrices([]);
          setCareCardDrugsPrices([]);
          setRxSenceDrugsPrices([]);
          setCostPlusDrugsPrices([]);
          setMedicationInfo([]);
          setSumOfBestPrciesAtPharmacies([]);
          setBestPackage([]);
          let rxLessDrugsBody = [];
          medicinesList?.forEach(async (x) => {
            let tempSelectedDrug = {
              name: x?.name,
              id: x?.id,
              type: x?.type,
              search_name: x?.search_name,
              dosage: x?.dosage,
              drugSeqNo: x?.drugSeqNo,
              form: x?.form,
              form_plural: x?.form_plural,
              package_size: x?.package_size,
              quantities: x?.quantities,
              default_quantity: x?.default_quantity !== undefined ? x?.default_quantity : JSON.parse(x?.quantities || [])[0],
              names: x?.names,
              ndc: x?.ndc,
              manufacturerOffer: x?.manufactorInfo,
              connectiveRxOffer: x?.crxNetPriceDTO?.crxNetPriceSubSeqNo ? x?.crxNetPriceDTO : undefined

            };
            rxLessDrugsBody.push(tempSelectedDrug);
            setHippoPrices(tempSelectedDrug, x);
            setCostPlusDrugPrices(tempSelectedDrug, x);
            setRxSencePrices(tempSelectedDrug, x);
            setCareCardInfo(tempSelectedDrug, x);

          });
          setRxLessPrices(rxLessDrugsBody);
        } else if (response?.status !== "OK") {
        }
      })
      .catch((e) => {
        // console.log(e);
      })
      .finally((e) => {
      });
  }

  async function setHippoPrices(selectedDrug, x) {
    let quantity = selectedDrug.default_quantity != "" ? selectedDrug.default_quantity : JSON.parse(selectedDrug.quantities)[0];
    let url = `prices?ndc=${selectedDrug?.ndc}&quantity=${quantity}&package_size=${selectedDrug?.package_size
      }&zip=${zip}&radius=15000`;

    dispatch(fetchDrugsPrices(url))
      .unwrap()
      .then(async (response) => {
        if (response?.length > 0) {
          const arr = [
            {
              quantity: selectedDrug?.default_quantity,
              package_size: selectedDrug?.package_size,
              search_name: selectedDrug?.search_name,
              drugSeqNo: selectedDrug?.drugSeqNo,
              drugPrices: response,
              selectedDrug: selectedDrug,
            },
          ];
          dispatch(setDrugDetails(response?.data));
          setHippoDrugsPrices((hippoDrugsPrices) =>
            hippoDrugsPrices
              ?.filter(
                (x, i) =>
                  x?.selectedDrug?.drugSeqNo !== selectedDrug?.drugSeqNo
              )
              .concat(arr)
          );

        }
        else {
          const arr = [
            {
              quantity: selectedDrug?.default_quantity,
              package_size: selectedDrug?.package_size,
              search_name: selectedDrug?.search_name,
              drugSeqNo: selectedDrug?.drugSeqNo,
              drugPrices: null,
              selectedDrug: selectedDrug,
            },
          ];

          setHippoDrugsPrices((hippoDrugsPrices) =>
            hippoDrugsPrices
              ?.filter(
                (x, i) =>
                  x?.selectedDrug?.drugSeqNo !== selectedDrug?.drugSeqNo
              )
              .concat(arr)
          );
          if (hippoApiErr === 0) {
            hippoApiErr = 1;
          }
        }
      })
      .catch((e) => {
        setIsLoader(false);
        dispatch(setAnimatedLoading(false));
      });
  }

  async function setCostPlusDrugPrices(selectedDrug, x) {
    let quantity = selectedDrug.default_quantity != "" ? selectedDrug.default_quantity : JSON.parse(selectedDrug.quantities)[0];
    let name = selectedDrug.name.toLowerCase() == "atorvastatin calcium" ? "Atorvastatin" : selectedDrug.name;
    let url =
      "https://us-central1-costplusdrugs-publicapi.cloudfunctions.net/main?medication_name=" +
      name +
      "&quantity_units=" +
      quantity;
    await dispatch(fetchCostPlusDrugPrice(url))
      .unwrap()
      .then((res) => {
        if (res?.results?.length > 0) {
          const arr = [{
            selectedDrug: selectedDrug,
            res: res,
            costPlusDrugPrice: res?.results?.length > 0 && res?.results?.[0]?.requested_quote
              ? Number(res?.results[0]?.requested_quote.replace(/[^0-9\.]+/g, ""))
              : 0,
          }]
          setCostPlusDrugsPrices((costPlusDrugsPrices) =>
            costPlusDrugsPrices
              ?.filter(
                (x, i) =>
                  x?.selectedDrug?.drugSeqNo !== selectedDrug?.drugSeqNo
              )
              .concat(arr)
          );
        }
        else {
          const arr = [{
            selectedDrug: selectedDrug,
            res: null,
            costPlusDrugPrice: 0,
          }]

          setCostPlusDrugsPrices((costPlusDrugsPrices) =>
            costPlusDrugsPrices
              ?.filter(
                (x, i) =>
                  x?.selectedDrug?.drugSeqNo !== selectedDrug?.drugSeqNo
              )
              .concat(arr)
          );
        }
      })
      .catch((e) => {
        dispatch(setAnimatedLoading(false));
        setIsLoader(false);
      });
  }

  async function setRxSencePrices(selectedDrug, x) {
    let quantity = selectedDrug.default_quantity != "" ? selectedDrug.default_quantity : JSON.parse(selectedDrug.quantities)[0];

    dispatch(fetchRxSenseDrugPrices({
      ndc: selectedDrug.ndc,
      quantity: parseFloat(quantity).toFixed(1),
      zipCode: zip
    })).unwrap().then((response) => {
      console.log({ rxSenseRes: JSON.parse(response.data) })

      if (response) {
        const arr = [
          {
            quantity: selectedDrug?.default_quantity,
            package_size: selectedDrug?.package_size,
            search_name: selectedDrug?.search_name,
            drugSeqNo: selectedDrug?.drugSeqNo,
            selectedDrug: selectedDrug,
            rxsenseResponse: JSON.parse(response?.data)
          },
        ];

        setRxSenceDrugsPrices((rxSenceDrugsPrices) =>
          rxSenceDrugsPrices
            ?.filter(
              (x, i) =>
                x?.selectedDrug?.drugSeqNo !== selectedDrug?.drugSeqNo
            )
            .concat(arr)
        );

        let phamacyList = JSON.parse(response?.data).Success ?
          JSON.parse(response?.data).Value.PharmacyPricings.map((pharmacy) => {
            return {
              pharmacyName: pharmacy.Pharmacy.Name.toLowerCase(),
              chain: pharmacy.Pharmacy.Name.toLowerCase().split(" ")[0],
              npi: pharmacy.Pharmacy.NPI
            }
          })
          : [];
        dispatch(setRxSenseDrugDetails(phamacyList));
      }

    }).catch((err) => {
      const arr = [
        {
          quantity: selectedDrug?.default_quantity,
          package_size: selectedDrug?.package_size,
          search_name: selectedDrug?.search_name,
          drugSeqNo: selectedDrug?.drugSeqNo,
          selectedDrug: selectedDrug,
          rxsenseResponse: null
        },
      ];

      setRxSenceDrugsPrices((rxSenceDrugsPrices) =>
        rxSenceDrugsPrices
          ?.filter(
            (x, i) =>
              x?.selectedDrug?.drugSeqNo !== selectedDrug?.drugSeqNo
          )
          .concat(arr)
      );
    })
  }

  async function setCareCardInfo(selectedDrug, x) {
    let postalCode = userPharmacy?.zipcode ? userPharmacy?.zipcode : ownPosition.postalCode;
    let quantity = selectedDrug.default_quantity != "" ? selectedDrug.default_quantity : JSON.parse(selectedDrug.quantities)[0];
    console.log(userPharmacy);
    dispatch(fetchCareCardInfo({
      ndc: selectedDrug?.ndc,
      quantity: +quantity,
      appUserSeqNo: user?.appUserSeqNo,
      zipCode: postalCode
    })).unwrap().then((response) => {
      if (response?.data?.length > 0) {

        const arr = [
          {
            quantity: +quantity,
            package_size: selectedDrug?.package_size,
            search_name: selectedDrug?.search_name,
            drugSeqNo: selectedDrug?.drugSeqNo,
            selectedDrug: selectedDrug,
            careCardResponse: response?.data?.map((x, i) => { return { ...x, pharmacy: x?.pharmacy, chain: x?.pharmacy?.name.toLowerCase() } })
          },
        ];
        setCareCardDrugsPrices((rxSenceDrugsPrices) =>
          rxSenceDrugsPrices
            ?.filter(
              (x, i) =>
                x?.selectedDrug?.drugSeqNo !== selectedDrug?.drugSeqNo
            )
            .concat(arr)
        );

      }
      else {
        const arr = [
          {
            quantity: +quantity,
            package_size: selectedDrug?.package_size,
            search_name: selectedDrug?.search_name,
            drugSeqNo: selectedDrug?.drugSeqNo,
            selectedDrug: selectedDrug,
            careCardResponse: null
          },
        ];

        setCareCardDrugsPrices((rxSenceDrugsPrices) =>
          rxSenceDrugsPrices
            ?.filter(
              (x, i) =>
                x?.selectedDrug?.drugSeqNo !== selectedDrug?.drugSeqNo
            )
            .concat(arr)
        );
      }
    }).catch((err) => {
      const arr = [
        {
          quantity: +quantity,
          package_size: selectedDrug?.package_size,
          search_name: selectedDrug?.search_name,
          drugSeqNo: selectedDrug?.drugSeqNo,
          selectedDrug: selectedDrug,
          careCardResponse: null
        },
      ];

      setCareCardDrugsPrices((rxSenceDrugsPrices) =>
        rxSenceDrugsPrices
          ?.filter(
            (x, i) =>
              x?.selectedDrug?.drugSeqNo !== selectedDrug?.drugSeqNo
          )
          .concat(arr)
      );
    })
  }


  const getRxLessDrugResponseMapper = (pharmData, drug) => {
    let drugPhamracies = [];
    const existingPharmacyIndex = (pharma) => {
      let index = drugPhamracies.findIndex(item => item.pharmacyName == pharma.pharmacyName);
      if (index != -1 && pharma.price < drugPhamracies[index]?.price) {
        drugPhamracies[index] = pharma
      }
      else if (index == -1) {
        drugPhamracies.push(pharma)
      }
    }
    pharmData.map((pharm) => {
      let containsDrug = pharm.meds.filter((x1) => x1.ndc == drug.ndc);
      if (containsDrug.length > 0) {
        let model = {
          pharmacyName: pharm.name,
          chain: pharm.name.toLowerCase(),
          city: pharm.city,
          zipCode: pharm.zip,
          lat: pharm.lat,
          lng: pharm.lng,
          state: pharm.state,
          npi: pharm.npi,
          quantity: containsDrug[0].qty,
          ndc: containsDrug[0].ndc,
          price: Math.min(...containsDrug[0].prices.map(price => price.amount)),
          bin: containsDrug[0].prices[0].bin,
          pcn: containsDrug[0].prices[0].pcn,
          groupId: containsDrug[0].prices[0].groupId,
          memberId: containsDrug[0].prices[0].memberId
        }
        existingPharmacyIndex(model)
      }
    })
    return drugPhamracies
  }

  async function setRxLessPrices(selectedDrugs) {
    let postalCode = userPharmacy?.zipcode ? userPharmacy?.zipcode : ownPosition.postalCode;
    console.log(userPharmacy);
    dispatch(fetchRxLessDrugPrices({
      appUserSeqNo: user?.appUserSeqNo,
      zipCode: postalCode,
      medications: selectedDrugs.map((drug, i) => {
        return {
          ndc: drug.ndc,
          qty: +(drug.default_quantity != "" ? drug.default_quantity : JSON.parse(drug.quantities)[0])
        }
      })
    })).unwrap().then((response) => {
      if (response?.data?.pharmData?.length > 0) {
        selectedDrugs?.map((drug, index) => {
          console.log({ rxless: getRxLessDrugResponseMapper(response?.data?.pharmData, drug) })

          let payload = [
            {
              quantity: +(drug.default_quantity != "" ? drug.default_quantity : JSON.parse(drug.quantities)[0]),
              package_size: drug?.package_size,
              search_name: drug?.search_name,
              drugSeqNo: drug?.drugSeqNo,
              selectedDrug: drug,
              rxLessResponse: getRxLessDrugResponseMapper(response?.data?.pharmData, drug)
            }
          ]
          setRxLessDrugsPrices((rxSenceDrugsPrices) =>
            rxSenceDrugsPrices
              ?.filter(
                (x, i) =>
                  x?.selectedDrug?.drugSeqNo !== drug?.drugSeqNo
              )
              .concat(payload)
          );
        })
      }
      else {
        selectedDrugs?.map((drug) => {
          let payload = [
            {
              quantity: +(drug.default_quantity != "" ? drug.default_quantity : JSON.parse(drug.quantities)[0]),
              package_size: drug?.package_size,
              search_name: drug?.search_name,
              drugSeqNo: drug?.drugSeqNo,
              selectedDrug: drug,
              rxLessResponse: null
            }
          ]
          setRxLessDrugsPrices((rxSenceDrugsPrices) =>
            rxSenceDrugsPrices
              ?.filter(
                (x, i) =>
                  x?.selectedDrug?.drugSeqNo !== drug?.drugSeqNo
              )
              .concat(payload)
          );
        })
      }
    }).catch((err) => {
      selectedDrugs?.map((drug) => {
        let payload = [
          {
            quantity: +(drug.default_quantity != "" ? drug.default_quantity : JSON.parse(drug.quantities)[0]),
            package_size: drug?.package_size,
            search_name: drug?.search_name,
            drugSeqNo: drug?.drugSeqNo,
            selectedDrug: drug,
            rxLessResponse: null
          }
        ]
        setRxLessDrugsPrices((rxSenceDrugsPrices) =>
          rxSenceDrugsPrices
            ?.filter(
              (x, i) =>
                x?.selectedDrug?.drugSeqNo !== drug?.drugSeqNo
            )
            .concat(payload)
        );
      })
    })
  }

  function resetInsuranceDetail() {
    setInsuranceDetail([]);
    setHasInsurance(null);
  }

  const AddOrUpdatePharmacy = async (pharmacy) => {
    await addOrUpdatePharmacy(user, pharmacy, (async (data) => {
      if (data.data.statusCode == 200) {
        setPharmacyFlag(false);
        setSelectMapValues(pharmacy);
        successToast("Default Pharmacy has been updated.");
        dispatch(resetProgressOperation("reset"));
        if (pharmacy?.userPharmacySeqNo) {
          dispatch(setPharmacyList(pharmacyList.map((phr, index) => {
            if (phr.userPharmacySeqNo == pharmacy.userPharmacySeqNo) return { ...phr, isDefault: true }
            else return { ...phr, isDefault: false }
          }).sort((a, b) => b.isDefault - a.isDefault)));
        }
        else {
          dispatch(setPharmacyList(pharmacyList.map((phr, i) => {
            if (i == 0) return data.data.data; else return { ...phr, isDefault: false };
          }).sort((a, b) => b.isDefault - a.isDefault)));
        }

        let user = JSON.parse(localStorage.getItem("user"));
        localStorage.setItem("user", JSON.stringify({ ...user, pharmacy: data.data.data }))

      }
      else {
        errorToast(data.data.message);
        dispatch(setAnimatedLoading(false));
        dispatch(setIsUpdatingPhamracy(false));
      }
    }))
  }

  const RemovePharmacy = async (pharmacy) => {
    let id = pharmacy?.userPharmacySeqNo;
    if (disabledIcons[id]) return;
    setDisabledIcons(prevState => ({ ...prevState, [id]: true }));

    await removePharmacy(user, pharmacy?.userPharmacySeqNo, (async (data) => {
      successToastWithId("Pharmacy has been removed.", "remove-pharmacy");
      await getUserPharmacyList(user, (data) => {
        dispatch(setPharmacyList(data.data.data.sort((a, b) => b.isDefault - a.isDefault)));
        setDisabledIcons(prevState => ({ ...prevState, [id]: false }));
        if (pharmacy.isDefault) {
          dispatch(setIsUpdatingPhamracy(true));
          dispatch(setIsRemovingPharmacy(true));
          setSelectMapValues([]);
          dispatch(resetProgressOperation("reset"));
          let user = JSON.parse(localStorage.getItem("user"));
          let defPharmacy = data.data.data.filter((phr) => phr.isDefault);
          if (defPharmacy.length > 0) {
            localStorage.setItem("user", JSON.stringify({ ...user, pharmacy: defPharmacy[0] }))
          }
          else {
            let model = {
              userPharmacySeqNo: null,
              appUserSeqNo: null,
              pharmacyName: null,
              latitude: null,
              longitude: null,
              zipcode: null,
              address: null,
              description: null,
              isDefault: null,
              npi: null,
              hasPharmacy: true,
              state: null,
              chain: null
            }
            localStorage.setItem("user", JSON.stringify({ ...user, pharmacy: model }))
          }
          setPharmacyFlag(false);
        }
      })
    }))
  }

  function onChangeSearch(event) {
    setDeleteShow(false);
    setSearchDrug(event.target.value);
    if (event.target.value.length == 1) {
    }
    if (event.target.value) dispatch(searchDrugsByName(event.target.value));
    else setOptionss([]);
  }

  const checkInsurance = (id, callBack) => {
    let userHasInsurance = JSON.parse(localStorage.getItem("user"))?.hasInsurance;

    if (!patientInsuranceInfo && userHasInsurance) {
      let user = JSON.parse(localStorage.getItem("user"));

      let body = {
        appUserSeqNo: user?.appUserSeqNo,
      };

      dispatch(fetchInsuranceInfo(body)).unwrap().then((response) => {
        if (response?.data?.length > 0) {
          let insuranceObj = {
            firstName: response.data[0]?.firstName,
            lastName: response.data[0]?.lastName,
            date_of_birth: response.data[0]?.dob,
            gender: response.data[0]?.gender,
            bin: response.data[0]?.bin,
            pcn: response.data[0]?.pcn,
            groupNumber: response.data[0]?.groupNumber,
            memberId: response.data[0]?.memberId,
            insInfoSeqNo: response.data[0]?.insInfoSeqNo
          };
          callBack(id, insuranceObj);
        }
        else {
          setHasInsurance(!hasInsurance);
          callBack(id, null);
        }

      }).catch((e) => {
        console.log(e);
      }).finally((e) => {
      });
    }
    else {
      callBack(id, null);
    }
  }

  const getInsuranceNumber = (insurance, insuranceObj) => {
    let objToReturn = {};
    if (!insuranceObj && insurance?.bin && insurance?.groupNumber && insurance?.memberId) {
      objToReturn = {
        bin: insurance?.bin,
        groupNumber: insurance?.groupNumber,
        memberId: insurance?.memberId,
        pcn: insurance?.pcn
      }
    }
    else if (insuranceObj && !insurance) {
      objToReturn = {
        bin: insuranceObj?.bin,
        groupNumber: insuranceObj?.groupNumber,
        memberId: insuranceObj?.memberId,
        pcn: insuranceObj?.pcn
      }
    }
    else {
      objToReturn = null
    }

    return objToReturn;
  }

  function drugDetails(drugId, insuranceObj) {
    setMedication({
      drugId: drugId,
    });
    let checkExistingList = customMedications?.filter(med => med.name.toLowerCase() === drugId);
    if(checkExistingList?.length > 0){
      let request = {
        appUserSeqNo: JSON.parse(localStorage.getItem("user"))
          ?.appUserSeqNo,
        drugSeqNo: null,
        ...checkExistingList[0],
        insurance: getInsuranceNumber(patientInsuranceInfo, insuranceObj),
        dob: JSON.parse(localStorage.getItem("user"))?.dob,
        pharmacyState: userHasPharmacy ? userPharmacy.state : ownPosition?.responseZIP?.region_code
      };
      setAddOrUpdateMed(request);
    }
    else if(drugId) {
      setAddingMedicine(true);
      dispatch(fetchMedicationDetail({ name: drugId, appUserSeqNo: user?.appUserSeqNo }))
        .unwrap()
        .then((response) => {
          if (response) {
            let mappedData = mapData(JSON.parse(response.data))
            let request = {
              appUserSeqNo: JSON.parse(localStorage.getItem("user"))
                ?.appUserSeqNo,
              drugSeqNo: null,
              ...mappedData,
              insurance: getInsuranceNumber(patientInsuranceInfo, insuranceObj),
              dob: JSON.parse(localStorage.getItem("user"))?.dob,
              pharmacyState: userHasPharmacy ? userPharmacy.state : ownPosition?.responseZIP?.region_code
            };
            setAddOrUpdateMed(request);
          }
        })
        .catch((e) => {
          setAddingMedicine(false);
        })
        .finally((e) => {
          resetDrugDetails();
        });
    }
    resetDrugDetails();
  }

  function resetDrugDetails() {
    setMedication({
      drugId: "",
    });
    setOptionss([]);
    setSearchDrug("");
  }

  function logout() {
    localStorage.clear();
    sessionStorage.clear();
    navigate("/");
  }

  const showDelete = () => {
    if (deleteShow) {
      setDeleteShow(false);
    } else {
      setDeleteShow(true);
    }
  };

  const setFinalPrices = (prices) => {
    setPricesList(prices);
  }

  useEffect(() => {
    if (addingMedicine)
      ariaAnnounce("please wait");
  }, [addingMedicine])


  return (
    <>{
      animatedLoading ? null :
        <>
          <>
            {!showPharmacyMap && !showCoupon && <section className="mt-3 mb-5" ref={ref}>
              <div className="container" >
                {/* <ProgressBar animated now={45} /> */}
                {addingMedicine &&
                  <div style={{ position: "absolute", top: "50%", left: "50%", zIndex: 1001 }}><Spinner animation="border" variant="primary" /></div>
                }
                <div className="row">
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    <div className="medicine_icon medicine_icon_m">

                      <div className="img-fluid pointer"
                        id="dropdownMenuButton1"
                        role="button" aria-label="Account menu" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">
                        <a href="javascript:void(0)">
                          <img
                            whileTap={{ scale: 0.95, transition: { duration: 0.1 } }}
                            src={avatar}
                            alt="Account"
                          ></img>
                        </a>

                      </div>
                    </div>
                  </div>
                </div>
                <div role="alert">
                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12" >
                      <div className="medication-heading">
                        <h1>My medication savings</h1>
                      </div>
                    </div>
                  </div>

                  {optionss?.length === 0 && (
                    <div className="row">
                      {medicationList?.length === 0 && (
                        <div className="col-sm-12 col-md-12 col-lg-12">
                          <div className="medications_detail">
                            <h4>Search below for your prescribed medications</h4>
                          </div>
                        </div>
                      )}
                    </div>
                  )}

                  <div className="row input-div">
                    <div className="col-sm-12 col-md-12 col-lg-9">
                      <div className="row">
                        <div
                          className={medicationList?.length > 0 ? 'col-11 col-sm-11 col-md-12 col-lg-12' : 'col-12 col-sm-12 col-md-12 col-lg-12'}
                        >
                          <input
                            value={searchDrug}
                            onChange={onChangeSearch}
                            placeholder="Search for a medication"
                            className="search-bar autoMargin medication-search-margin"
                            id="searchMedicineInput"
                            aria-label="search for a medication"
                          />
                          {optionss?.length > 0 && searchDrug != "" && (
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                              <div className="searchBar-icon " >
                                {optionss.map((searchResult) => (
                                  <a href="javascript:void(0)" onClick={() => checkInsurance(searchResult.id, drugDetails)}
                                  >
                                    <li
                                      className="list-style">
                                      <a>{searchResult.name}</a>
                                      <h5>{searchResult.secondary_name}</h5>
                                    </li>
                                  </a>
                                ))}
                              </div>
                            </div>
                          )}
                        </div>

                        {medicationList?.length > 0 && (<div
                          className="col-1 col-sm-1 col-md-12 col-lg-12 mobileView ps-0"
                          style={{ textAlign: "end" }}
                        >
                          <div className="vertical-ellipse">
                            <a href="javascript:void(0)"
                              onClick={() => showDelete()}>
                              <img className=""
                                src={trash}
                                alt="Delete"
                              ></img>
                            </a>
                          </div>

                        </div>)}
                        {(fullMedicinesList.length == 0 && !searchDrug && !addingMedicine) && (
                          <div>
                            {windowWidth > 600 ?
                              <>
                                <img src={arrowDesktop} style={{ height: 43, position: "relative", top: -45, left: -20 }} />
                                <img src={desktopImage} style={{ position: "relative", top: -53, left: -10, maxWidth: "102%" }} className="img-fluid" />
                              </>

                              : <>
                                <img src={arrowMobile} style={{ height: 36, position: "relative", top: -45, left: -12 }} />
                                <img src={mobileImage} style={{ position: "relative", top: -54, left: -13, maxWidth: "103%" }} className="img-fluid" />
                              </>
                            }
                          </div>
                        )}

                      </div>

                      <div className="row">
                        <div className="col-12">
                          {windowWidth < 992 && medicationList?.length > 0 &&
                            <>
                              {cheaperOptionAvailable ?

                                <div className="offer-section" style={{ marginTop: -6, marginBottom: 10 }}>
                                  <div style={{ display: "flex", alignItems: "center" }}><i class="fa-solid fa-circle-exclamation" style={{ fontSize: 21 }}></i><span>Your price may be lower at <a href="javascript:void(0)" onClick={() => {
                                    if (pencilClick) {
                                      dispatch(setPencilClick(false))
                                      setSavingOptions(false)
                                    }
                                    else {
                                      dispatch(setPencilClick(true))
                                      setSavingOptions(true)

                                    }
                                  }}>a different pharmacy.</a></span>
                                  </div>
                                </div>
                                :
                                <>
                                  {(pharmacyInfo?.bestPrice > 0 || pharmacyInfo?.hasInsurance) && (
                                    <div className="offer-section" style={{ marginTop: -6, marginBottom: 10 }}>
                                      <div style={{ display: "flex", alignItems: "center" }}><i class="fa-solid fa-tag" style={{ color: "#40DFB9", fontSize: 26 }}></i>
                                        <span>Congratulations! Your pharmacy has the lowest total price for your drugs in your area!</span>
                                      </div>
                                    </div>
                                  )}
                                </>
                              }
                            </>
                          }
                        </div>
                      </div>
                      {
                        (
                          <div className="tabMargin">
                            <PricesDetails
                              insuranceInfo={insuranceDetail}
                              hasInsurance={hasInsurance}
                              npi={npi}
                              param={medication}
                              resetDrugDetails={resetDrugDetails}
                              medicationsParam={setMedicationCoupon}
                              totalValuesParam={setSelectMapValues}
                              setMedAndDrugPrices={setMedDrugData}
                              deleteShowParam={deleteShow}
                              savingOptionParam={SavingOptions}
                              refParam={ref}
                              setMedicationsLength={setMedicationsLength}
                              setSavingOptionsPram={setSavingOptions}
                              ownPosition={ownPosition}
                              setOriginalFilter={setOriginalFilter}
                              setPharmacyFlag={setPharmacyFlag}
                              valParam={originalFilter != null && originalFilter[0]}
                              setPharmacyData={setPharmacyInfo}
                              pharmacyParam={pharmacyInfo}
                              setFinalPrices={setFinalPrices}
                              medicationList={medicationList}
                              fullMedicinesList={fullMedicinesList}
                              fetchMoreMedicines={fetchMoreMedicines}
                              medicationInfo={medicationInfo}
                              medicationsLength={medicationsLength}
                              setMedicationInfo={setMedicationInfo}
                              setAddOrUpdateMed={setAddOrUpdateMed}
                              quantity={quantity}
                              setQuantity={setQuantity}
                              label={label}
                              setLabel={setLabel}
                              form={form}
                              setForm={setForm}
                              dosage={dosage}
                              setDosage={setDosage}
                              setRemoveDrug={setRemoveDrug}
                              bestPackage={bestPackage}
                              addingMedicine={addingMedicine}
                            />
                          </div>)}
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-3 couponMobFixed">
                      <RightReviewCoupon
                        medParam={medicationCoupon}
                        valParam={originalFilter != null && originalFilter[0]}
                        setSavingOptionsPram={setSavingOptions}
                        savingOptionParam={SavingOptions}
                        refParam={ref}
                        medDrugData={medicationInfo}
                        ownPosition={ownPosition}
                        pricesList={pricesList}
                        cheaperOptionAvailable={cheaperOptionAvailable}
                        windowWidth={windowWidth}
                        medicationInfo={medicationInfo}
                        bestPackage={bestPackage}
                        ref={rightReviewRef}
                        insuranceDetail={insuranceDetail}
                        prismaPrices={prismaPrices}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>}
            {
              showPharmacyMap &&
              <SearchPharmacy
                ownPosition={ownPosition}
                setOwnPosition={setOwnPosition}
                pharmacyList={pharmacyList}
                setPharmacyFlag={setPharmacyFlag}
                setPharmacyList={(list) => dispatch(setPharmacyList(list))}
                AddOrUpdatePharmacy={AddOrUpdatePharmacy}
                RemovePharmacy={RemovePharmacy}
                drugPrices={drugPrices}
                resetInsurance={resetInsuranceDetail}
                setLoading={setLoading}
              />
            }
          </>

          {showCoupon &&
            <CouponCard
              ownPosition={ownPosition}
              cheaperOptionAvailable={cheaperOptionAvailable}
              windowWidth={windowWidth}
              insuranceDetail={insuranceDetail}
              originalFilter={originalFilter != null && originalFilter[0]}
              pharmacyInfo={bestPackage?.filter((x, i) => x?.chain === originalFilter?.[0]?.chain)}
            />
          }
        </>
    }
    </>
  );
};

export default MedicationSearch;