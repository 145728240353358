import React from 'react'
import { useState, useEffect, useRef } from 'react';
import { motion } from "framer-motion";
import avatar from "../../assets/images/RxLink assets/avatar icon.svg";
import editIcon from "../../assets/images/RxLink assets/edit icon.svg";
import closeIcon from "../../assets/images/RxLink assets/close icon.svg";
import { MotionTextH2 } from "../motion/MotionText";
import { useFormik } from 'formik';
import './myAccount.css';
import * as Yup from "yup";
import { fetchInsuranceInfo } from '../../reducers/getInsuranceInfo';
import { useDispatch } from 'react-redux';
import { saveProfile } from '../../reducers/submitProfile';
import InputMask from "react-input-mask";
import { genderOptions } from '../insurance/content';
import 'react-datepicker/dist/react-datepicker.css';
import { successToast, errorToast } from '../toast/toast';
import DateOfBirth from '../Fields/DateOfBirth';
import { useStyleMediaQuery } from '../coupan/hooks';
import { setInsuranceInfo } from '../../reducers/global';
import TickCross from '../../utilities/TickCross';
import ariaAnnounce from '../../utilities/useAnnounceText';
import LoadingComponent from '../LoadingComponent';
import { useGetUser } from '../../utilities/useGetUser';
import PopoverInsurance from '../Popover';

const MyAccount = () => {
    const dispatch = useDispatch();
    const [initialData, setInitialData] = useState(null)
    const [editProfileEnabled, setEditProfileEnabled] = useState(false)
    const [editInsuranceEnabled, setEditInsuranceEnabled] = useState(false)
    const [loading, setLoading] = useState(false)
    const pickerRef = useRef(null)
    const userDetail = useGetUser();
    const { matches: isMobile } = useStyleMediaQuery({ mixOrMax: 'max', widthOrHeight: 'width', value: 575 });
    const dobFieldClassName = isMobile ? "col-12 col-sm-12 col-md-12 col-lg-12 form-group" : "col-6 col-sm-6 col-md-6 col-lg-6 form-group";

    const [errorMonth, setErrorMonth] = useState("121");
    const [errorYear, setErrorYear] = useState("121");
    const [errorDay, setErrorDay] = useState("121");

    const resetFields = () => {
        setEditInsuranceEnabled(false)
        setEditProfileEnabled(false)
    }

    useEffect(() => {
        if (pickerRef.current !== null)
            pickerRef.current.input.readOnly = true;
    }, [pickerRef])

    useEffect(() => {
        if (!editProfileEnabled && initialData) {
            initialData.firstName && formik.setFieldValue("firstName", initialData.firstName)
            initialData.middleName && formik.setFieldValue("middleName", initialData.middleName)
            initialData.lastName && formik.setFieldValue("lastName", initialData.lastName)
            initialData.gender && formik.setFieldValue("gender", initialData.gender)
            initialData.email && formik.setFieldValue("email", initialData.email)
            initialData.address && formik.setFieldValue("address", initialData.address)
            userDetail?.username && formik.setFieldValue("username", userDetail?.username)
            if (/^\d{8}$/.test(initialData.dob) && parseInt(initialData.dob)) {
                const year = parseInt(initialData.dob.substr(0, 4));
                const month = parseInt(initialData.dob.substr(4, 2)) - 1;
                const day = parseInt(initialData.dob.substr(6, 2));
                const convertedDate = new Date(year, month, day);
                formik.setFieldValue("dob", convertedDate)
            }

        }
        if (!editInsuranceEnabled && initialData) {
            initialData.pcn && formik.setFieldValue("pcn", initialData.pcn)
            initialData.bin && formik.setFieldValue("bin", initialData.bin)
            initialData.groupNumber && formik.setFieldValue("groupNumber", initialData.groupNumber)
            initialData.memberId && formik.setFieldValue("memberId", initialData.memberId)
        }

    }, [editProfileEnabled, editInsuranceEnabled])

    const checkNullOrUndefined = (arg) => {
        if (arg === undefined || arg === null) {
            return false;
        }
        else {
            return true;
        }
    }
    useEffect(() => {

        if (!loading) {
            dispatch(fetchInsuranceInfo({ appUserSeqNo: userDetail?.appUserSeqNo })).unwrap().then((res) => {
                if (res.data && res.data.length) {
                    let {
                        memberId,
                        groupNumber,
                        pcn,
                        bin,
                        firstName,
                        middleName,
                        lastName,
                        insInfoSeqNo,
                        patientSeqNo,
                        hasAlerts,
                        email,
                        address,
                        dob,
                        gender,
                    } = res.data[0]
                    res.data[0] && setInitialData(res.data[0]);
                    res.data[0] && dispatch(setInsuranceInfo(res.data[0]));
                    checkNullOrUndefined(firstName) && formik.setFieldValue("firstName", firstName);
                    checkNullOrUndefined(middleName) && formik.setFieldValue("middleName", middleName);
                    lastName && formik.setFieldValue("lastName", lastName);
                    (pcn || pcn === "") && formik.setFieldValue("pcn", pcn);
                    bin && formik.setFieldValue("bin", bin);
                    groupNumber && formik.setFieldValue("groupNumber", groupNumber);
                    memberId && formik.setFieldValue("memberId", memberId);
                    hasAlerts && formik.setFieldValue("hasAlerts", hasAlerts);
                    email && formik.setFieldValue("email", email);
                    (address || address === "") && formik.setFieldValue("address", address);
                    gender && formik.setFieldValue("gender", gender);
                    userDetail?.username && formik.setFieldValue("username", userDetail?.username);
                    insInfoSeqNo && formik.setFieldValue("insInfoSeqNo", insInfoSeqNo);
                    patientSeqNo && formik.setFieldValue("patientSeqNo", patientSeqNo);
                    if (/^\d{8}$/.test(dob) && parseInt(dob)) {
                        const year = parseInt(dob.substr(0, 4));
                        const month = parseInt(dob.substr(4, 2)) - 1;
                        const day = parseInt(dob.substr(6, 2));
                        const convertedDate = new Date(year, month, day);
                        formik.setFieldValue("dob", convertedDate)
                    }
                }
                else {
                    userDetail?.username && formik.setFieldValue("username", userDetail?.username);
                }
            })
        }
    }, [loading])


    const validationSchema = Yup.object().shape({
        firstName: Yup.string(),
        lastName: Yup.string(),
        bin: Yup.string(),
        pcn: Yup.string(),
        groupNumber: Yup.string(),
        memberId: Yup.string(),
        dob: Yup.string().when(['bin', 'pcn', 'memberId', 'groupNumber'], {
            is: (bin, pcn, memberId, groupNumber) => {
                if (bin || pcn || memberId || groupNumber) return true;
                else return false;
            },
            then: (schema) => schema.required("Required"),
            otherwise: (schema) => schema.min(0),
        }),
        gender: Yup.string().when(['bin', 'pcn', 'memberId', 'groupNumber'], {
            is: (bin, pcn, memberId, groupNumber) => {
                if (bin || pcn || memberId || groupNumber) return true;
                else return false;
            },
            then: (schema) => schema.required("Required"),
            otherwise: (schema) => schema.min(0),
        }),
    });

    const formik = useFormik({
        initialValues: {
            firstName: "",
            middleName: "",
            lastName: "",
            email: "",
            username: "",
            address: "",
            bin: "",
            pcn: "",
            groupNumber: "",
            memberId: "",
            dob: "",
            gender: "",
            insInfoSeqNo: "",
            patientSeqNo: "",
            hasAlerts: false,
        },
        validationSchema,
        onSubmit: (data) => {
            if (loading) return;
            setLoading(true);
            let body = {
                insInfoSeqNo: data?.insInfoSeqNo,
                appUserSeqNo: userDetail?.appUserSeqNo,
                patientSeqNo: userDetail?.patientSeqNo,
                firstName: data?.firstName,
                middleName: data?.middleName,
                lastName: data?.lastName,
                dob: data?.dob ? String(data?.dob?.getFullYear()) + String(data?.dob?.getMonth() + 1).padStart(2, '0') + String(data?.dob?.getDate()).padStart(2, '0') : '',
                gender: data?.gender,
                address: data?.address,
                memberId: data?.memberId,
                groupNumber: data?.groupNumber,
                bin: data?.bin,
                pcn: data?.pcn,
                hasAlerts: data?.hasAlerts,
            }
            dispatch(saveProfile(body))
                .unwrap()
                .then((response) => {
                    setLoading(false)
                    if (response?.statusCode === 200 & response?.status === "OK")
                        successToast("Profile has been updated successfully.");
                    resetFields()
                    console.log({ formik });
                    formik.setTouched(false)
                    if (response?.statusCode === 200 & response?.status === "OK") {
                        response?.data && dispatch(setInsuranceInfo(response?.data));
                        setInitialData(response?.data);
                        let user = JSON.parse(localStorage.getItem("user"));
                        localStorage.setItem("user", JSON.stringify({ ...user, firstName: response?.data?.firstName, lastName: response?.data?.lastName, dob: response?.data?.dob, hasInsurance: true, gender: response?.data?.gender }))
                    } else if (response?.status !== "OK") {
                        errorToast(response?.message);
                    }
                })
                .catch((e) => {
                    setLoading(false);
                });
        },
    });

    const firstNameRef = useRef(null);
    const lastNameRef = useRef(null);
    const dobRef = useRef(null);
    const genderRef = useRef(null);
    const binRef = useRef(null);
    const pcnRef = useRef(null);
    const grpNumRef = useRef(null);
    const memberIdRef = useRef(null);

    const { isSubmitting, isValidating } = formik;

    useEffect(() => {
        if (isSubmitting) {
            if (Object.keys(formik.errors).length > 0) {
                const firstErrorField = Object.keys(formik.errors).find(
                    (fieldName) => formik.touched[fieldName]
                );
                if (firstErrorField) {
                    switch (firstErrorField) {
                        case "firstName":
                            firstNameRef.current && firstNameRef.current.focus();
                            break;
                        case "lastName":
                            lastNameRef.current && lastNameRef.current.focus();
                            break;
                        case "dob":
                            if (errorMonth || errorMonth == "121") {
                                window.document.getElementById("accountmonthInput")?.focus();
                                break;
                            }
                            if (errorDay || errorDay == "121") {
                                window.document.getElementById("accountdayInput")?.focus();
                                break;
                            }
                            if (errorYear || errorYear == "121") {
                                window.document.getElementById("accountyearInput")?.focus();
                                break;
                            }
                            break;
                        case "gender":
                            genderRef.current && genderRef.current.focus();
                            break;
                        case "bin":
                            binRef.current && binRef.current.focus();
                            break;
                        case "pcn":
                            pcnRef.current && pcnRef.current.focus();
                            break;
                        case "groupNumber":
                            grpNumRef.current && grpNumRef.current.focus();
                            break;
                        case "memberId":
                            memberIdRef.current && memberIdRef.current.focus();
                            break;
                        default:
                            break;
                    }
                }
            }
        }
    }, [isSubmitting, isValidating]);

    useEffect(() => {
        ariaAnnounce("My Account")
    }, [])
    return (
        <>
            {!loading ?
                <section className="mt-3 mb-5">
                    <form onSubmit={formik.handleSubmit} id="accountForm">
                        <div className="container account">
                            <div className="row">
                                <div className="col-6 col-sm-6 col-md-6 col-lg-6">

                                    <div className="medicine_icon pharma_icon">
                                        {/* <MotionIcon> */}
                                        <a className='txtDecoration' href='javascript:void(0)' aria-label="Go Back" onClick={() => window.history.back()}>
                                            <i className="fas fa-angle-left" alt="" ></i>
                                        </a>
                                        {/* </MotionIcon> */}
                                    </div>

                                </div>
                                {/* aria-expanded="false" */}
                                <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                                    <div className="medicine_icon medicine_icon_m">
                                        <div className="img-fluid pointer"
                                            id="dropdownMenuButton1"

                                            type="button"
                                            data-bs-toggle="offcanvas"
                                            data-bs-target="#offcanvasRight"
                                            aria-controls="offcanvasRight">
                                            <a href="javascript:void(0)">
                                                <img
                                                    whileHover={{ scale: 1.1 }}
                                                    src={avatar}
                                                    alt="Account"

                                                />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-12">
                                    <div className="medication-heading" role='alert'>
                                        <MotionTextH2 text="My Account" duration={0.03} delay={0.15} />
                                    </div>
                                </div>
                            </div>
                            <div className='sub_div'>
                                <span className="sub_heading">PERSONAL INFORMATION</span>
                                {
                                    !editProfileEnabled ?
                                        <a
                                            onClick={() => setEditProfileEnabled(prev => !prev)} href="javascript:void(0)">
                                            <img
                                                whileHover={{ scale: 1.1 }}
                                                src={editIcon}
                                                className="edit_icon img-fluid pointer"
                                                alt="edit personal information"
                                            />
                                        </a>
                                        :
                                        <a
                                            onClick={() => setEditProfileEnabled(prev => !prev)} href="javascript:void(0)">
                                            <img
                                                whileHover={{ scale: 1.1 }}
                                                src={closeIcon}
                                                className="close_icon img-fluid pointer"
                                                alt="close personal information form"
                                            />
                                        </a>
                                }
                            </div>
                            <div className='card_view mt-3 mb-3 col-sm-12 col-md-12 col-lg-12'>
                                <motion.div
                                    className="row"
                                    initial={{ x: 300, opacity: 0 }}
                                    animate={{ x: 0, opacity: 1 }}
                                    transition={{ duration: 0.8, delay: 0.25 }}
                                    exit={{ x: 300 }}
                                >
                                    <div className="col-6 col-sm-6 col-md-6 col-lg-6 form-group forget_input_eye">
                                        <input
                                            type='text'
                                            name='firstName'
                                            style={{ width: "95%" }}
                                            className={`form-control  custom-form-control ${!editProfileEnabled && "disabled-input"}` +
                                                (formik.errors.firstName && formik.touched.firstName
                                                    ? " is-invalid"
                                                    : "")}
                                            placeholder='First Name'
                                            disabled={!editProfileEnabled}
                                            onChange={formik.handleChange}
                                            value={formik.values.firstName}
                                            id="accountFirstNameInput"
                                            aria-label="First Name"
                                            ref={firstNameRef}
                                            aria-required={true}
                                        >
                                        </input>
                                        {TickCross(formik, "firstName", "error-circle-acount")}
                                    </div>
                                    <div className="col-6 col-sm-6 col-md-6 col-lg-6 form-group">
                                        <input
                                            type='text'
                                            name='middleName'
                                            className={`form-control  custom-form-control ${!editProfileEnabled && "disabled-input"}` +
                                                (formik.errors.middleName && formik.touched.middleName
                                                    ? " is-invalid"
                                                    : "")}
                                            placeholder='Middle Name'
                                            disabled={!editProfileEnabled}
                                            onChange={formik.handleChange}
                                            value={formik.values.middleName}
                                            id="accountMiddleNameInput"
                                            aria-label="Middle Name"
                                        >
                                        </input>
                                    </div>
                                    <div className="col-6 col-sm-6 col-md-6 col-lg-6 form-group forget_input_eye">
                                        <input
                                            type='text'
                                            name='lastName'
                                            style={{ width: "95%" }}
                                            className={`form-control  custom-form-control ${!editProfileEnabled && "disabled-input"}` +
                                                (formik.errors.lastName && formik.touched.lastName
                                                    ? " is-invalid"
                                                    : "")}
                                            placeholder='Last Name'
                                            disabled={!editProfileEnabled}
                                            onChange={formik.handleChange}
                                            value={formik.values.lastName}
                                            id="accountLastNameInput"
                                            aria-label="Last Name"
                                            ref={lastNameRef}
                                            aria-required={true}
                                        >
                                        </input>
                                        {TickCross(formik, "lastName", "error-circle-acount")}

                                    </div>
                                    <div className="col-6 col-sm-6 col-md-6 col-lg-6 form-group">
                                        <input
                                            type='text'
                                            name='email'
                                            className={`form-control  custom-form-control ${true && "disabled-input"}` +
                                                (formik.errors.email && formik.touched.email
                                                    ? " is-invalid"
                                                    : "")}
                                            placeholder='Email'
                                            disabled={true}
                                            onChange={formik.handleChange}
                                            value={formik.values.email}
                                            id="accountEmailInput"
                                            aria-label="Email"
                                        >
                                        </input>
                                    </div>
                                    <div className="col-6 col-sm-6 col-md-6 col-lg-6 form-group">
                                        <InputMask
                                            name='username'
                                            className={`form-control  custom-form-control ${true && "disabled-input"}` +
                                                (formik.errors.username && formik.touched.username
                                                    ? " is-invalid"
                                                    : "")}
                                            mask="(999) 999-9999"
                                            maskChar={null}
                                            placeholder='Phone Number'
                                            disabled={true}
                                            onChange={formik.handleChange}
                                            value={formik.values.username}
                                            id="accountPhoneNumberInput"
                                            aria-label="Mobile Phone Number"
                                        >
                                        </InputMask>
                                    </div>
                                    <div className="col-6 col-sm-6 col-md-6 col-lg-6 form-group">
                                        <input
                                            type='text'
                                            name='address'
                                            className={`form-control  custom-form-control ${!editProfileEnabled && "disabled-input"}` +
                                                (formik.errors.address && formik.touched.address
                                                    ? " is-invalid"
                                                    : "")}
                                            placeholder='Address'
                                            disabled={!editProfileEnabled}
                                            onChange={formik.handleChange}
                                            value={formik.values.address}
                                            id="accountAddressInput"
                                            aria-label="Address"
                                        >
                                        </input>
                                    </div>
                                </motion.div>
                            </div>
                            <div className='divider mt-3 mb-3' />
                            <div className='sub_div'>
                                <span className="sub_heading">
                                    INSURANCE
                                    {/* <span className='mobile-only-popover'>Where do I find my insurance information? */}
                                    <span style={{ paddingLeft: 4 }}>
                                        <PopoverInsurance place="right" />
                                    </span>
                                    {/* </span> */}

                                </span>
                                {
                                    !editInsuranceEnabled ?
                                        <a href="javascript:void(0)" onClick={() => setEditInsuranceEnabled(prev => !prev)}>
                                            <img
                                                whileHover={{ scale: 1.1 }}
                                                src={editIcon}
                                                className="edit_icon img-fluid pointer"
                                                alt="edit insurance"
                                            />
                                        </a>
                                        :
                                        <a href="javascript:void(0)" onClick={() => setEditInsuranceEnabled(prev => !prev)}>
                                            <img
                                                whileHover={{ scale: 1.1 }}
                                                src={closeIcon}
                                                className="close_icon img-fluid pointer"
                                                alt="close edit insurance form"
                                            />
                                        </a>
                                }
                            </div>
                            <div className='card_view mt-3 mb-3 col-sm-12 col-md-12 col-lg-12'>
                                <motion.div
                                    className="row"
                                    initial={{ x: 300, opacity: 0 }}
                                    animate={{ x: 0, opacity: 1 }}
                                    transition={{ duration: 0.8, delay: 0.25 }}
                                    exit={{ x: 300 }}
                                >
                                    <div className="col-6 col-sm-6 col-md-6 col-lg-6 form-group forget_input_eye">
                                        <input
                                            type='text'
                                            name='bin'
                                            style={{ width: "95%" }}
                                            className={`form-control  custom-form-control ${!editInsuranceEnabled && "disabled-input"}` +
                                                (formik.errors.bin && formik.touched.bin
                                                    ? " is-invalid"
                                                    : "")}
                                            placeholder='BIN'
                                            disabled={!editInsuranceEnabled}
                                            onChange={formik.handleChange}
                                            value={formik.values.bin}
                                            id="accountBinInput"
                                            aria-label="BIN"
                                            ref={binRef}
                                            aria-required={true}
                                        >
                                        </input>
                                        {(editInsuranceEnabled && formik.errors.bin && formik.touched.bin) && TickCross(formik, "bin", "error-circle-acount")}
                                    </div>
                                    <div className="col-6 col-sm-6 col-md-6 col-lg-6 form-group">
                                        <input
                                            type='text'
                                            name='pcn'
                                            style={{ width: "95%" }}
                                            className={`form-control  custom-form-control ${!editInsuranceEnabled && "disabled-input"}` +
                                                (formik.errors.pcn && formik.touched.pcn
                                                    ? " is-invalid"
                                                    : "")}
                                            placeholder='PCN'
                                            disabled={!editInsuranceEnabled}
                                            onChange={formik.handleChange}
                                            value={formik.values.pcn}
                                            id="accountPcnInput"
                                            aria-label="PCN"
                                            ref={pcnRef}
                                        >
                                        </input>
                                    </div>
                                    <div className="col-6 col-sm-6 col-md-6 col-lg-6 form-group forget_input_eye">
                                        <input
                                            type='text'
                                            name='groupNumber'
                                            style={{ width: "95%" }}
                                            className={`form-control  custom-form-control ${!editInsuranceEnabled && "disabled-input"}` +
                                                (formik.errors.groupNumber && formik.touched.groupNumber
                                                    ? " is-invalid"
                                                    : "")}
                                            placeholder='Group'
                                            disabled={!editInsuranceEnabled}
                                            onChange={formik.handleChange}
                                            value={formik.values.groupNumber}
                                            id="accountGroupNumberInput"
                                            aria-label="Group"
                                            ref={grpNumRef}
                                            aria-required={true}
                                        >
                                        </input>
                                        {(editInsuranceEnabled && formik.errors.bin && formik.touched.bin) && TickCross(formik, "groupNumber", "error-circle-acount")}
                                    </div>
                                    <div className="col-6 col-sm-6 col-md-6 col-lg-6 form-group forget_input_eye">
                                        <input
                                            type='text'
                                            name='memberId'
                                            style={{ width: "95%" }}
                                            className={`form-control  custom-form-control ${!editInsuranceEnabled && "disabled-input"}` +
                                                (formik.errors.memberId && formik.touched.memberId
                                                    ? " is-invalid"
                                                    : "")}
                                            placeholder='Member ID'
                                            disabled={!editInsuranceEnabled}
                                            onChange={formik.handleChange}
                                            value={formik.values.memberId}
                                            id="accountMemberIdInput"
                                            aria-label="Member ID"
                                            ref={memberIdRef}
                                            aria-required={true}
                                        >
                                        </input>
                                        {(editInsuranceEnabled && formik.errors.bin && formik.touched.bin) && TickCross(formik, "memberId", "error-circle-acount")}
                                    </div>
                                    <div className={dobFieldClassName + ' forget_input_eye'} style={{ width: "95%" }}>
                                        <DateOfBirth
                                            formik={formik}
                                            fieldName={'dob'}
                                            enabled={editInsuranceEnabled}
                                            id="account"
                                            ariaLabel=""
                                            errorMonth={errorMonth}
                                            errorYear={errorYear}
                                            errorDay={errorDay}
                                            setErrorDay={setErrorDay}
                                            setErrorMonth={setErrorMonth}
                                            setErrorYear={setErrorYear}
                                            deskClass={(editInsuranceEnabled && formik.errors.bin && formik.touched.bin) ? "error-circle-account" : null}
                                            mobileClass={(editInsuranceEnabled && formik.errors.bin && formik.touched.bin) ? "error-circle-account-mobile" : null}
                                        />
                                    </div>
                                    <div className="col-6 col-sm-6 col-md-6 col-lg-6 form-group forget_input_eye">
                                        <select
                                            name="gender"
                                            style={{ color: formik.values.gender === "" && "gray", width: "95%" }}
                                            defaultValue=""
                                            className={
                                                `form-select  custom-form-control custom-form-select ${!editInsuranceEnabled && "disabled-input"}` +
                                                (formik.errors.gender && formik.touched.gender
                                                    ? " is-invalid form-select-is-invalid"
                                                    : "")
                                            }
                                            onChange={formik.handleChange}
                                            value={formik.values.gender}
                                            disabled={!editInsuranceEnabled}
                                            id="accountGenderInput"
                                            aria-label="Birth Sex"
                                            ref={genderRef}
                                            aria-required={true}
                                        >
                                            <option value="" hidden disabled defaultValue="">Birth Sex*</option>
                                            {genderOptions.map(option => (
                                                <option key={option.value} value={option.value} style={{ color: "black" }}>
                                                    {option.label}
                                                </option>
                                            ))}
                                        </select>

                                        {(editInsuranceEnabled && formik.errors.bin && formik.touched.bin) && TickCross(formik, "gender", "error-circle-acount")}
                                    </div>
                                </motion.div>
                            </div>
                            <div className="col-12 col-sm-1 col-md-1 col-lg-1 pb-3">
                                <button
                                    type="submit"
                                    className="btn_success"
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                    </form>
                </section>
                :
                <LoadingComponent />
            }
        </>
    )
}

export default MyAccount
